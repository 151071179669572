import { api } from '../utils/api';
import { decodeToken } from 'react-jwt';

async function signin(user, password) {
  // call backend

  const { data } = await api.post('login', {
    username: user,
    password,
  });

  const { token, expiredPassword, isFirstLogin, mfaEnabled, mfaSet } = data;

  const decoded = decodeToken(token);

  return {
    ...decoded,
    token: data,
    expiredPassword,
    isFirstLogin,
    mfaEnabled,
    mfaSet,
  };
}

async function mask(user) {
  const { data } = await api.post('/mask', {
    username: user,
    password: 'FAKE',
    impersonate: true,
  });

  const { token, expiredPassword, isFirstLogin, mfaEnabled, mfaSet } = data;

  await api.post('/impersonate', {
    token,
  });

  const decoded = decodeToken(token);

  return {
    ...decoded,
    token: data,
    expiredPassword,
    isFirstLogin,
    mfaEnabled,
    mfaSet,
  };
}

async function unmask() {
  const { data } = await api.post('/unmask', {});

  const { token, expiredPassword, isFirstLogin, mfaEnabled, mfaSet } = data;

  await api.post('/impersonate', {
    token,
  });

  const decoded = decodeToken(token);

  return {
    ...decoded,
    token: data,
    expiredPassword,
    isFirstLogin,
    mfaEnabled,
    mfaSet,
  };
}

async function refresh() {
  const { data } = await api.post('/refresh');

  const decoded = decodeToken(data);

  return {
    ...decoded,
    token: data,
  };
}

async function logout() {
  await api.post('/logout');
}

export default {
  signin,
  refresh,
  logout,
  mask,
  unmask,
};
