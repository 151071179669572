import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AppraisalItem {
  uuid: string;
  data:
    | {
        costs: Array<{
          id: string;
          family: string;
          key: string;
          value: number;
        }>;
        costsByFamily: Array<{ id: string; value: number }>;
        costsTotal: number;
        vehicleData: {
          vehiclePlate: string;
          vehicleMake: string;
          vehicleModel: string;
          vehicleYear: string;
          vehicleDoors: number;
          vin: string;
          vehicleRegistrationDate: string;
          dataBIProvider2: {
            dataImmatricolazione: string;
            tipoVeicolo: string;
            quotazioneEurotaxBluTotale: number;
          };
          vehicleGearbox: string;
          vehicleFuel: string;
          vehiclePower: number;
          vehicleFourWheelDrive: string;
        };
        ownerData: {
          ownerName: string;
          ownerSurname: string;
          phone: string;
          email: string;
          legalEntity: string;
        };
        managementData: {
          expectedVehicleReturnDate: string;
          expectedVehicleMileage: number;
          taxSystem: string;
          vehiclePropertyDefinition: string;
        };
        appraisalCompletion: {
          scoreS1: number;
          scoreS2: number;
          scoreS3: number;
          scoreS4: number;
          scoreS5: number;
        };
        dataBIProvider2Bind: {
          motornetCode: string;
          motornetDescription: string;
        };
      }
    | Record<string, never>;
}
interface AppraisalState {
  data: Array<AppraisalItem>;
  queryData: Array<any>;
}

export const appraisalsSlice = createSlice({
  name: 'appraisals',
  initialState: {
    data: [],
    queryData: [],
  } as AppraisalState,
  reducers: {
    setData: (state, { payload }: PayloadAction<AppraisalItem>) => {
      const findIndex = state.data.findIndex((d) => d.uuid === payload.uuid);
      const freshData = payload;
      const dataToSet =
        findIndex === -1
          ? [...state.data, freshData]
          : state.data.map((d, index) => (index === findIndex ? freshData : d));
      return { ...state, data: dataToSet };
    },
    forgeNewAppraisal: (state, { payload: { uuid, data = {} } }: PayloadAction<AppraisalItem>) => {
      return { ...state, data: [...state.data, { uuid, data }] };
    },
    setQueryData: (state, { payload: { uuid, data } }: PayloadAction<AppraisalItem>) => {
      const findIndex = state.queryData.findIndex((d) => d.uuid === uuid);
      const freshData = { uuid, data };
      const dataToSet =
        findIndex === -1
          ? [...state.queryData, freshData]
          : state.queryData.map((d, index) => (index === findIndex ? freshData : d));
      return { ...state, queryData: dataToSet };
    },
  },
});

export const { forgeNewAppraisal, setData, setQueryData } = appraisalsSlice.actions;

export default appraisalsSlice.reducer;
