import { createSlice } from '@reduxjs/toolkit';

export const evaluationsSlice = createSlice({
  name: 'evaluations',
  initialState: {
    visibleEvaluationDialog: false,
    evaluation: {},
  },
  reducers: {
    setVisibleEvaluationDialog: (state, action) => {
      return { ...state, visibleEvaluationDialog: action.payload };
    },
    setEvaluation: (state, { payload: { meta, data } }) => {
      return { ...state, evaluation: { ...state.evaluation, meta, data } };
    },
    setEvaluationMeta: (state, { payload: meta }) => {
      return { ...state, evaluation: { ...state.evaluation, meta } };
    },
    setEvaluationData: (state, { payload: data }) => {
      return { ...state, evaluation: { ...state.evaluation, data: { ...state.evaluation.data, ...data } } };
    },
  },
});

export const { setVisibleEvaluationDialog, setEvaluation, setEvaluationMeta, setEvaluationData } =
  evaluationsSlice.actions;

export default evaluationsSlice.reducer;
