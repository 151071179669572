import React, { createContext, useState, useCallback, useContext, useMemo } from 'react';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import './use-snackbar.scss';

const SnackbarContext = createContext(undefined);

export const SnackbarProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = useState({ open: false });

  const handleSnackbarClose = () => setSnackbarData((state) => ({ ...state, open: false }));

  const anchorOrigin = useMemo(() => {
    if (snackbarData.hasOwnProperty('anchorOrigin')) {
      return snackbarData.anchorOrigin;
    }
    return { vertical: 'bottom', horizontal: 'center' };
  }, [snackbarData]);

  return (
    <SnackbarContext.Provider value={{ setSnackbarData }}>
      {children}
      <MuiSnackbar
        open={snackbarData.open}
        autoHideDuration={4500}
        onClose={handleSnackbarClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarData.severity}>
          {snackbarData.message}
        </Alert>
      </MuiSnackbar>
    </SnackbarContext.Provider>
  );
};

export const commonSnackbarDataAsSuccess = (message) => ({
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  severity: 'success',
  message,
});

export const commonSnackbarDataAsError = (message) => ({
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  severity: 'error',
  message,
});

export const commonSnackbarDataAsInfo = (message) => ({
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  severity: 'info',
  message,
});

export const useSnackbar = () => {
  const { setSnackbarData } = useContext(SnackbarContext);

  const showSnackbarMessage = useCallback((data) => setSnackbarData({ open: true, ...data }), [setSnackbarData]);
  const hideSnackbarMessage = useCallback(() => setSnackbarData({ open: false }), [setSnackbarData]);

  const showSnackbarMessageSuccess = (message) => showSnackbarMessage(commonSnackbarDataAsSuccess(message));
  const showSnackbarMessageError = (message) => showSnackbarMessage(commonSnackbarDataAsError(message));
  const showSnackbarMessageInfo = (message) => showSnackbarMessage(commonSnackbarDataAsInfo(message));

  return {
    showSnackbarMessage,
    hideSnackbarMessage,
    showSnackbarMessageSuccess,
    showSnackbarMessageError,
    showSnackbarMessageInfo,
  };
};

export default useSnackbar;
