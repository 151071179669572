import { createSlice } from '@reduxjs/toolkit';

export const DEFAULT_FILTER_VERSION = { applied: 0, changed: 0 };

export const FILTER_TYPES = ['em1', 'em2', 'bm', 'free', 'stock', 'positioning', 'positioning-vehicle'];

export const filterPanelSlice = createSlice({
  name: 'filterPanel',
  initialState: {
    filterData: undefined,
    filterVersions: FILTER_TYPES.reduce((acc, type) => ({ ...acc, [type]: DEFAULT_FILTER_VERSION }), {}),
    filterDataDomains: undefined,
  },
  reducers: {
    setFilterData: (state, { payload }) => {
      state.filterData = payload;
    },
    clearFilterData: (state) => {
      state.filterData = undefined;
    },
    setFilterVersion: (state, { payload }) => {
      state.filterVersions = Object.assign({}, state.filterVersions, { [payload.type]: payload.version });
    },
    updateFilterVersion: (state, { payload: { type, update } }) => {
      let version = state.filterVersions[type];
      update.forEach((member) => {
        version = { ...version, [member]: (version[member] || 0) + 1 };
      });
      state.filterVersions = Object.assign({}, state.filterVersions, { [type]: version });
    },
    clearFilterVersions: (state) => {
      state.filterVersions = undefined;
    },
    setFilterDataDomains: (state, { payload }) => {
      state.filterDataDomains = payload;
    },
    // setFilterDataDomains: (state, { payload: { type, update } }) => {
    //     state.filterDataDomains = Object.assign({}, state.filterDataDomains, { [type]: update });
    // },
    clearFilterDataDomains: (state) => {
      state.filterDataDomains = undefined;
    },
  },
});

export const {
  setFilterData,
  clearFilterData,
  setFilterVersion,
  updateFilterVersion,
  clearFilterVersions,
  setFilterDataDomains,
  clearFilterDataDomains,
} = filterPanelSlice.actions;

export default filterPanelSlice.reducer;
