import React from 'react';

const SvgComponent = (props, ref) => (
  <svg
    data-testid="icon"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...{ ...{ width: '1em', height: '1em' }, ...props }}
  >
    <image
      width="512"
      height="512"
      preserveAspectRatio="none"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0eNT6AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAACAASURB
VHic7d15vF1Vfffxz01uEsI8k4QAYQ4ogyIgBUURBbXq41S1dZ6qVh+ts31pnaq1pfapLUVsrVqt
1VrtY8UBFRURBQsikyJzmCJhChAyQm76x7qhN2nuvef89jpn77325/16rZc+T/2d/O69Z+/zPWuv
vTZIkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkiRJkqRKRupuQGqpbYAdgTl1N9Jx
a4B7gVV1NyK1jQFAmt6OwMnACcDRwIHAbrV2pM0tA64BLgJ+AvwQuL/WjiRJrTQTeC5wFulb5gZH
q8Zq4D+AZwIzkCRpGqPA64Ebqf9DzJFnXAO8EoOAJGkSJwG/pv4PLMdgxqWkyziSJAEwF/g7YIz6
P6Qcgx3rgdNw8aYkdd4C4OfU/8HkGO74GTAPSVInLQZupf4PI0c9YwlwAJKkTjkUuJ36P4Qc9Y5b
Sbd1Sp3jPgDqonmkaf+9625EjXAjcCxwZ92NSMPkbTHqmtnA1/HDX/9jX+DfSbeASp0xs+4GpCH7
CPCCuptQ4ywi3SHw45r7kIbGSwDqkmNJq7+d+dKWPAgcQ9ovQCqeJ0J1xQjwcXzPa3KzgNPxi5E6
wje6uuL5wFcyvt4DwHWkOwlWZHxd9W47YD7pVr5tMr7us4BvZHw9SVKNLqb6LWMPAV8AnkRaTKhm
mEN6WuMXSX+jqn/n84fbviRpUB5Png+Fg4bduPq2GLiA6n/vo4fduCQpv09R7cPgdLxFrE1mAZ+k
2t/8E0PvWpKU1UzgLuIfBP+Ia2XaaAT4HPG/+1JcMCpJrfYY4h8Cl+A3/zabBVxO/O9/2PBblobH
hKvSnRis2wC8lrSoTO30IPCHpL9lxOMz9iI1jgFApTsiWPdD0p0DarcLgJ8Eax+VsxGpaQwAKl10
5f5nsnahOv1TsM6nBEpSi91B7PqvDwsqx77E3gO31tGsJKm62cAY/Z/476qjWQ3MCLCc/t8HD+ID
01QwLwGoZPOJ3cK3JHMfqtcGYn/TUWD3vK1IzWEAUMnmB+uWZu1CTRD9m0bfQ1LjGQBUsgXBut9m
7UJNEA0A0feQ1HgGAJXMAKCNon9TZwBULAOASuYlAG0UDQDOAKhYBgCVLBoAnAEoj2sApM0YAFSy
6Lc3ZwDK4wyAtBkDgErmJQBt5AyAJHXInfS/+ctDuPlLiUaB9fT/fritjmYlSXHRXQD99l+u2zEQ
Sg/zEoBKFd0F0ABQrsg6gJm4G6AKZQBQqbwDQJtzHYA0gQFApfIOAG3O3QClCQwAKpUzANqcuwFK
ExgAVCpvAdTm3AtAmsAAoFL5HABtzjUA0gQGAJXKGQBtzhkAaQIDgErlDIA25wyAJHWAuwBqc+4G
KEmFcxdATcbdAKVxXgJQiebhLoDassjf2N0AVSQDgErk9X9Nxr0ApHEGAJXIOwA0GXcDlMYZAFQi
ZwA0GWcApHEGAJXIbYA1GfcCkMYZAFQiLwFoMu4FII0zAKhEPglQk3ENgDTOAKASuQZAk3ENgCQV
7A763+xlPWmnOJXN3QAlqVCziJ3gnf7vDncDlPASgMozn9j72gDQHe4GKGEAUHm8BVDTcR2AhAFA
5fEOAE3HOwEkDAAqj3cAaDruBSBhAFB5vASg6bgboIQBQOVxF0BNxzUAEgYAlcdLAJqOawAkDAAq
jzMAmo4zAJJUIHcB1HRGSRv7uBugJBXCXQDVK3cDVOd5CUAlie4C6PX/7nE3QHWeAUAl8fq/euU6
AHWeAUAlcRdA9co7AdR5BgCVxE2A1Ct3A1TnGQBUEgOAeuVugOo8A4BK4iUA9co1AOo8A4BK4gyA
euUaAHWeAUAlcQZAvXIGQJIK4i6A6pW7AUpSIdwFUP1yN0B1mpcAVIp5uAug+uNugOo0A4BK4fV/
9cu9ANRpBgCVwjsA1C/3AlCnGQBUCmcA1C9nANRpBgCVwhkA9csZAHWaAUClMACoX+4FoE4zAKgU
XgJQv9wNUJ1mAFAp5gXrnAHoLtcAqNMMACrFLoGaMWBZ7kbUGstIm0f1a+fcjUh1MACoFNsHau4j
7eymbnoIuD9QF3mvSY1jAFAptg7U+OGvsUDNNtm7kGpgAFApVgZqfP8r8h5Ynb0LqQaeAFWKBwI1
u+CCri6bD+wUqFuRuxGpDgYAlSL6mNajs3ahNjkmWHdH1i6kmhgAVIrfBOtemLULtcmLgnVXZe1C
qokBQKX4dbDuecDCnI2oFfYCnhOsjYZNSdIAHAtsCI6v1tCv6vUl4u+XE2roV5I0iZnAcuIn9VcN
v2XV5NXE3ycrgNnDb1mSNJUvED+xrwVePPyWNWQvIf2to++Trw2/ZUnSdE4ifmLfQNoU5kPAVsNu
XAM3F/gz0t+4ynvkGcNuXJI0vRnAtVQ7wW8AbgReBmw33PY1ANuT/pZLqP6+uA0YHWr30gCN1N2A
lNnLgc9meq01wI+Aa4Bbie8bf2SmfrrmUuK/84XAwcATgTmZ+nkL8IlMryXVzgCg0oySbgk8sO5G
VJSlwAG4DbAK4j4AKs1DwOtIU7ZSLu/AD38VZmbdDUgDcCOwL069K4/vAO+uuwkpNy8BqFQ7Aj8H
Dqq7EbXaMuAo4s+akBrLSwAq1b3AM4H76m5ErfUg8AL88FehDAAq2dWkvf69dqt+jQGvAX5cdyOS
pLgnA6uofh+4oxtjPWnvAElSAY4j3cpV94eLo9ljBfBcJElFWQCcT/0fMo5mjuuAw5EkFWkEeC1p
h7m6P3AczRhjwKeAbZEkFW8h8EmqPRnO0f5xNvAYJEmdswj4KOlWr7o/jBzDGWuALwMnIHWYGwFJ
yUzgCaQ7Bk4GDgNm19mQsroH+AHwfeBr4/9vqdMMANKWjQL7kR4qtD3p0cA7BV5nH+D1gbqbgcsC
dU10BLB3oO6TwE2Bup1Jq/lXkJ7k+Jvx1xkLvJYkSSHHE5um/nQdzQ7Ip4n9Do6vo1mpK9wJUJKk
DjIASJLUQQYASZI6yAAgSVIHGQAkSeogA4AkSR1kAJAkqYMMAJIkdZABQJKkDjIASJLUQQYASZI6
yAAgSVIHGQAkSeqg0bobkDKbw/88vhdgNrBNfe1wcLBuV+ConI3UaNdg3cHAmpyN9GkNsHr8v68i
PV54ZX3tSHmN1N2A1KNdgAOA/YFFwB7A/PGxO+kZ8NuRAoA0KOuB+4F7gDvGx23AMuBW4Drg+vH/
P6nRDABqmm2Bw4EjgCPH//NgYMc6m5L6tAq4FrgcuGx8/BK4u86mpIkMAKrbHsDjxscJpA/8mbV2
JA3O1cD5wE/Gxw31tqMuMwBo2GYAjwJOBp4BHIeLUdVdNwDnAN8EvgesrbcddYkBQMPyCOAl42NB
zb1ITbQK+BbwBeBs4MF621HpDAAapN2AV46Pg2ruRWqTZcC/AGeSFhZK2RkANAjHAG8Cno+r8qUq
xkiXBv6eNDuwod52JGnLTgDOIp2kHA5H3nEF8FJcJCupQR4LnEf9J0iHowvjKuB5SFKNFgKfJ01T
1n1SdDi6Ni4k3UUjhTiVpIhR4J3AV0nb1bqWRBq+hcArSHfVnIe3EKpPnrjVr0cCnwGOrrsRSQ/7
LfAG4Ot1N6L2cAZAvRoB3g58Bdir5l4kbWo74IXAnqSNhR6qtx21gTMA6sUuwOeA3625j0FZweBO
mKPU+zTCNlvJYP8u2037v2qny4HfI207LE3KAKDpHAF8A9i77kaAdaSnrN0G3AwsJT2B7RbSFOib
gBcFXvcJwI/ztKiWOBE4N1D3JeB00nX3haTZsAXj/7lw/L/PytNiJStIu27+Z92NqLlG625AjXYq
acq/jm9Ky0mrnC8Crhwf1zL1N8KXDaEvddv9wM+m+L/PIj298pGkp1oeTdoYa/vBt7aJ7YCvAW8D
PjHkf1tSy72KtBf5sG5pWk46Yf0hcAix2akzg//2iYF/S+12IrH3ypmBf2sGKRC8kfSN/P7gvx0d
n8DZXkk9egPDubd/KfB3pJNxjgWpBgD1apgBYHOzSE/D/CRwe7CPfsc/4FM3JU3jTQz2w38N6Trq
k8h/QjIAqFd1BoCJZgKnkC61rQ321Ov4JwwBkibxCgb34f9b4D2kOwoGxQCgXjUlAEy0K/Be0pMA
BxUCTh9g/5Ja6hTSKvvcJ5xbSNf1txrCz2AAUK+aGAA2mku6DHdbsMfpxjuH8DNIaokjyL8waTnw
LtLJbFgMAOpVkwPARlsD7wbuDfY62RgjbRokqeO2Jj1dLOcJ5izSjmTDZgBQr9oQADaaR3roVs5j
9AHgwGH+EGoeF4ToNGBxptdaBjxjfNyW6TWlrrsdeClpJ85lmV5zG+CzuB18pxkAuu0U4PWZXut7
pEsJ38z0epI29S3SMXZ2ptc7nvR8D3WUAaC75gJnkGeDkL8Fnkq+byeStmwZ8DTgg6Sp/KreD+yf
4XXUQgaA7no/sF/F11hH2n73zaSFRZIGbwPwAdJe/+sqvtZcvDWwswwA3XQI8NaKr7EWeC5pcZKk
4fsi8GzS5lpVnAo8r3o7ahsDQDedRrUnlq0BnoXX+6W6fZu0OLBqCPgYMLt6O2oTA0D3PBF4eoX6
DcCrge/maUdSRT8AXsDUT8qczv6kzYfUIQaAbhkhffuv4h2kqUdJzfENqu/w917qefS3amIA6Jan
A0dVqD8P+OtMvUjK6xPA+RXqd8FZgE4xAHTLeyrUrgVeR55bjyTlN0a6PLe2wmu8Hdg2TztqOgNA
dzwJ+J0K9R8lbRksqbmuJi3oi9oVeFWmXtRwBoDueHOF2l9T7aQiaXj+nGph/Y342dAJ/pG74QCq
rfx/K9U3HJE0HGtJTxGMOoC0s6cKZwDohtcR/1tfgLf8SW1zFnBxhXoXA3aAAaB8o8AfVKj/QKY+
JA3Pxu2Co04B5udpRU1lACjfU0nPE4+4kPSUP0nt8y3SMRwxk2pfHNQCBoDyvbhC7QezdSGpDh+u
UPuSbF2okQwAZduK+GKea/Hav9R2ZwM3BWsPBw7K2IsaxgBQtlOIb+35Wdz0R2q7MdKxHPXMXI2o
eQwAZYsevOvxMb9SKT5DOqYjnpWzETWLAaBsTw7WnQ3clrMRSbW5Bfh+sPaxwPYZe1GDGADKdRCw
V7D2n3M2Iql20Rm9UeDxORtRcxgAynVSsO5BXPwnlea7xC8DRM8lajgDQLmOD9ZdCNyfsxFJtbsH
uChYGz2XqOEMAOU6NlgXvVYoqdmiM3tHALNzNqJmMACUaWfSAz0iDABSmc4O1s0BHpmzETWDAaBM
hwMjgbr7iE8TSmq2i4hf3jsyZyNqBgNAmQ4J1l1GfKGQpGZbD1wZrD04ZyNqBgNAmaIHa/TkIKkd
rgjWLc7ahRrBAFCmA4N1v8rahaSmiQYAZwAKZAAoU3QDIGcApLJdHqxbmLULNYIBoEx7BuucAZDK
dlWwbhtgh5yNqH4GgPLMJd0G2K+1wN2Ze5HULHcDDwVr5+dsRPUzAJRnp2DdPVm7kNREG4gH/cgX
CzWYAaA80Wk6v/1L3XBXsM6nAhbGAFCe6EG6PGsXkprKACDAAFCiOcE6LwFI3RA91rfK2oVqZwAo
T/RvGl0YJKkbItuLq8EMAOWJ/k2jMweS2sUn+wkwAJRoLFhnAJC6YTRYtyFrF6qdAaA8K4N1BgCp
G6IzANFzixrKAFAeA4CkqcwK1j2QtQvVzgBQnuhBumPWLiQ11a7BOgNAYQwA5bkjWLcPrvKVumDv
YN2dWbtQ7QwA5VkD3Buo2wrYI3Mvkppld2DrYO3SnI2ofgaAMt0erNs3axeSmib67f8BvARQHANA
mW4K1hkApLJFA8DNWbtQIxgAynRNsM7HfUpliz7R79qsXagRDABlih6s7vUtlS16/T/6pUINZgAo
05JgnbcCSmXbKVh3XdYu1AgGgDJFn/YVPTlIaofoJYDo7cVqMANAmSK3AYIBQCpd9BiPnlPUYAaA
MhkAJG1JdAZgedYu1AgGgDIZACRtSfQYNwAUyABQppXAukCdiwClskWP8fuydqFGMACUKzILsH32
LiQ1ybaBmvXA/bkbUf0MAOWKbNu5TfYuJDVJJACsADbkbkT1MwCUa1WgZg7xZ4VLar5IAFiZvQs1
ggGgXJEAAM4CSKWaBcwO1EXPJWo4A0C5oqndACCVKfLtH5wBKJYBoFzR1B49SUhqtmi4dwagUAaA
ckVTuwFAKlP0QUAGgEIZAMq1OljnIkCpTKPBOgNAoQwA5VofrJuZtQtJTRENAA9m7UKNYQAoVzQA
RE8SkpotemxHzyVqOANAuQwAkiYyAGgTBoByjQXrDABSmQwA2oQBoFyuAZA0UfTYNgAUygBQLi8B
SJrIGQBtwgBQLi8BSJrIAKBNGADK5QyApImix3b0y4QazgBQLtcASJrINQDahAGgXM4ASJrISwDa
hAGgXK4BkDSRAUCbMACUyxkASRMZALQJA0C5ogftSNYuJDVFdFbQRYCFMgCUK/pUv3VZu5DUFNGH
+viloFAGgHLNCdb55C+pTNFje3bWLtQYBoByRQ9aZwCkMkWP7eiXCTWcC77K5QxA754OHFh3Exqq
g+puoAbOAGgTBoByRQ/aLgaAd9TdgDQE0RkAA0ChvARQrugMgJcApDI5A6BNGADK5QyApImix7Zr
AAplACiXMwCSJvISgDZhACiXiwAlTeQlAG3CAFAubwOUNJEBQJswAJTLGQBJE7kPgDZhACiXMwCS
JnIGQJswAJTLuwAkTbQ2WOcMQKEMAOXaJli3KmsXkppiZbBuq6xdqDEMAOXaIVh3f9YuJDXFQ8QC
/va5G1EzGADKFTlo1xGfJpTUfCsCNQaAQhkAyjSb2LRd5OQgqT0ix/gosHXuRlQ/HwZUJqf/+3MG
cH3dTWio9gfeUHcTNYge49vj+qDiGADKFJ2y6+oMwFeAH9fdhIbqRLoZAKLH+A7A7TkbUf28BFAm
ZwAkbUmVGQAVxgBQJmcAJG1J9Bg3ABTIAFCm6AyAAUAqW3QGIHpOUYMZAMoUTeteApDK5gyAHmYA
KJMzAJK2xACghxkAyuQMgKQtcRGgHmYAKJOLACVtiTMAepgBoEw7Bevuy9qFpKa5J1i3Y9Yu1AgG
gDLtHqy7M2sXkprmrmDdblm7UCMYAMoUDQDRk4OkdjAA6GEGgDI5AyBpS6LHuAGgQAaAMkUPVmcA
pLItB9YH6gwABTIAlGcOsRW764F7M/ciqVnGiC0E3BGYlbkX1cwAUJ7dgJFA3V2kk4OkskVm+kaA
XXM3onoZAMrjAkBJU3EdgAADQImiB6kLAKVu8E4AAQaAEjkDIGkqBgABBoASeQugpKl4CUCAAaBE
3gIoaSrOAAgwAJTIACBpKgYAAQaAEs0L1nkJQOqGZcG6PbJ2odoZAMqzMFh3W9YuJDXV0mDdnlm7
UO0MAOUxAEiaSvRYNwAUxgBQlrnAzsHa6LcCSe1yL7AyUDcPGM3ci2pkAChL9Nv/cmB1zkYkNVok
8M8kvsZIDWQAKEt0is7pf6lbvAwgA0BhvP4vqRcGABkAChMNAF7/l7olesxHzzFqIANAWbwEIKkX
zgDIAFAYA4CkXhgAZAAojJcAJPUiGgC8BFAQA0BZXAQoqRfuBigDQEFGiT8K+Lc5G5HUeL8FxgJ1
ewIjmXtRTQwA5diLtFFHv9YRfziIpHZaB9weqJuLTwUshgGgHPsF624B1udsRFIr3BSsW5SzCdXH
AFCOfYN1N2btQlJbRI/96LlGDWMAKEf0oFySswlJrbEkWGcAKIQBoBwGAEn9WBKsW5SxB9XIAFAO
A4CkfiwJ1jkDUAgDQDlcAyCpH64B6DgDQBm2Jr4HwJKMfUhqj5uJ7QWwD352FME/Yhn2JbY5x1pi
9wJLar91xHYEnA0syNyLamAAKEN0Su4mYt8AJJVhSbDOywAFMACUwev/kiKi54BFOZtQPQwAZagy
AyCpu5YE66I7j6pBDABlODBY5wyA1G03BOv2z9qFamEAKMNBwbprsnYhqW2uDdZFzzlqEANA+40S
vwQQPfgllcEA0GEGgPbbD5gVqNtAfPpPUhnuAO4N1O0E7Jq5Fw2ZAaD9otf/bwNW5mxEUis5C9BR
BoD2ix6ETv9LAgNAZxkA2i86A2AAkATxc0H03KOGMAC0nzMAkqpwBqCjDADt5y2AkqqIngsMAC1n
AGi3ucCewVpnACRBPAAciJ8hreYfr90OIvY3HMNdACUl9wF3BuqqfAFRAxgA2u3gYN3NwJqcjUhq
tegswOKsXWioDADt9ohg3W+ydiGp7aLnhEOydqGhMgC026HBul9n7UJS210VrIueg9QABoB2i84A
RA92SWWKfikwALSYAaC9ZgEHBGudAZA0kQGggwwA7XUQsYcAgTMAkjZ1M7AiULcLsEfmXjQkBoD2
ik7/LwWW52xEUuttAK4O1joL0FIGgPZyAaCknLwM0DEGgPYyAEjKKXpp0FsBW8oA0F7RSwAGAElb
4gxAxxgA2mk28UdxugBQ0pZEA0D0y4hqZgBop4OJ3wHgDICkLbkRWB2o2x3vBGglA0A7HRmsWwrc
lbMRScVYD/wqWHt4zkY0HAaAdjosWHd51i4klSZ6jjAAtJABoJ2OCNYZACRN5YpgnQGghQwA7RQ9
2KIHt6RucAagQwwA7bMbMC9Ye1nORiQVJ3qOOJR0d5JaxADQPtHp/3XEt/qU1A13kxYL92s26fkk
ahEDQPtEA8BVpBAgSVOJXgaInptUEwNA+3gHgKRBip4roucm1cQA0D7RlH1l1i4klSoaAKL7k6gm
BoB22Yr4tpsuAJTUi+i54tFZu9DAGQDa5QjiWwBfkrMRScW6ClgZqNsN2DtzLxogA0C7HBWsuwm4
M2cjkoq1nvgswGNyNqLBMgC0SzQA/CJrF5JKd3GwLnqOUg0MAO0STdcGAEn9iJ4zDAAtYgBoj62A
Q4K10TQvqZuiAcBLAC1iAGiPI4kvAPxlzkYkFe8q4IFA3S7AorytaFAMAO0RnVpbggsAJfVnDLg0
WOtlgJYwALSHCwAlDZPrAApnAGgPA4CkYTIAFM4A0A4uAJQ0bNFzx2OAkZyNaDAMAO0QXQC4AXcA
lBRzNbAiULczLgRsBQNAO0RvrVlCer63JPXLhYCFMwC0Q/RgcvpfUhXuCFgwA0A7uABQUh3cEKhg
BoDm24b4AkCv/0uqosqdAC4EbDgDQPMdDYwG6jbgDICkaq4B7g/U7QQsztyLMjMANN9xwbrrgHty
NiKpc8aAi4K1v5OzEeVnAGi+6EH0s6xdSOqqnwbrol9eNCQGgGYbAY4N1l6QsxFJnRU9lxgAGs4A
0GwHALsFa50BkJTDhaRLAf06hLQpkBrKANBs0QR9P/DrnI1I6qx7SY8H7tcIcEzmXpSRAaDZogHg
AmB9zkYkdVp0RtHLAA0Wub1MwxM9eBYCXwbuGh/LSHcFXAfcRGw6T1I37ATsDywAdh0fOwVfyzsB
GswA0FzbAI8I1j5iitoHgF+S9gg4BzgXWBn8dyS1267ASaSp+kcDhwO7ZHz9Y4GZOCPZSAaA5jqJ
wfx9tgUeNz7eAqwFzge+B5wNXD6Af1NSM8wmfSt/CvBk0of+IC8Fbzf+b0T3EpA6YwT4XeA/gAdJ
u/kNe/wKeAcwb8A/6yCcSexnPrGOZlWrE4m9V86so9mKRkiB/9PAfQz/nLKK9HtzPYC0BbOBl5M+
fOv40N/SeBA4C3gO7ZkpMgCoV10IAIuAPyWt/an7fLJxnA88DZ8TIDELeBNwC/UfmFON64E3AHMH
82vIxgCgXpUcAI4GvgI8RP3njsnGJcBzMQioo55Ms77x9zKWAe8lviJ40AwA6lWJAeAE0qxd3eeJ
fsaPSQsPpU5YBHyd+g+8KmM58C6aNyNgAFCvSgoATwIupv7zQnQ8CPwNsEPuX4zUJM8nfXjWfcDl
GrcAryXd5tMEBgD1qoQAcChpqr/u80CucTvp7gQNiTsBDscOwL+QDtYda+4lp4XAp0jfPk6ouRep
K+YB/wxcQfpSUYo9gG8DH6I9C49bzQAweIeTNt75g7obGaAjgfOAf8SHf0iDMhP4I+A3wEsp8/w9
E3gf8CPSToQaoBLfQE1yKum2l33rbmQIRoBXkx4a8pKae5FKcyTwU+B0unGt/ATS5kFH1t1IyQwA
g/Mq4BuknbC6ZHfg86RdBU3wUjWzgA+QLrMdW28rQ7eANLN4St2NlMoAMBgfIO26NavmPup0CnAZ
6V5fSf07gvTB/36as9B22LYjfZH6vbobKZELLfJ7N+mAHbSlpJPDFcANpHv015K2+tyBtKBmt/Fx
IOnbw95D6GuiXYGvkmYE/oj0ICJJU5sBvBP4IGmX0DrcQjq33El6ougdwL3j/7e5pG/nC0kPHTuU
wX7ZmU1aRL2atM+B1EhvYrC3yfyCdP/9I4P9zQOeCXwcuHHAvW4+rgIOC/bdK28DVK9OpJm3Ae4O
fDfYW3SsBy4gzVz+LunLQz/mACcDp5G+jAyqz9WkPQ+kxnkZMEb+N/1K4JPEHw08lUcDHyFt9TuM
E81K4JUD+Dk2MgCoV00MAE8Abgv21e94EPgO8IfA/Mw/x3Gkb+xrB9D3CuCxmfuVKjkWWEPeN/pq
0u5Yw3gq3wzSAzq+Rfo2MOiTz6cYzNSmAUC9aloAeBvD2bv/VtI3/T0H9HNMtA9pLVTuJ5v+FhcY
qyH2IB1UOd/g3wL2H+YPMcF+pFuNcgeazcdP6H+qcToGAPWqKQFgLunb8qA/+P+LtCC3jnVfhwLn
9tFrL+On1Lc+QgLSwpfzyPemvpP0+N0m2As4g8FM420cN5N3XYABQL1qQgCYR7rXfZAf/BeQZvfq
NkK6THov+X62vx/qTyBt5sPkezP/iOFMy/Vrb+BfGcz6hg2kZyM8MVOvBgD1qu4AsJjBLsT9FfDU
TL3mtB9pNiLXz/m84bYvJUcA68jzJv5Lmn+f7+NIz/AexMlqDfDCDD0aXIrPRQAADo9JREFUANSr
OgPACcDdwX9/unE38EaafYv3bNJlxhw/7+3ALsNtvxwjdTfQUqPAhcBRFV9nDPhj4G8rdzQcM4HX
AX9O/h0ONwBfJj0MJOoVwEmBuv8kLSxSd8wHnhWo+yHw2Qr/7jGkYyj3ffMbSM/ieA9wT+bXHpT3
kO5Cqvo59HnS5QVpKN5J9eS6jvbubrU36RaiQXyDcTgc/Y2rgcfTTq8gz10Cpw67cXXTfNKOdlXe
rGOkp3m13ctIOw/WfQJ0OLo4HgI+BmxFu72a6r+La/CuAA3BP1D9zfquoXc9OPuQ904Ih8Mx/biJ
tHFQKT5A9d/J/x120+qWxVSfrjpj6F0P3ijwIYaziZDD0fXxZcp8JPCnqfZ7uRPYfuhdqzM+R7U3
6JW0f7puKk8jLUCq+wTpcJQ4HqSs2cPNzQEupdrvqOTfj2q0kGqb4qwBDh9618N3AOkpYnWfLB2O
ksYddONBOIeStkGP/p5uJ+2uKGV1GtUO4LcMv+XabAd8jfpPmg5HCeNnNHOTsEF5G9V+X68Zfssq
2RzS9aUqB3DX9lwYAd7H4HYQdDi6MD5N91a3zyDt9R/9nV08/JZVshcRfzOOkTb/6KqXMJynnDkc
JY0x0sr4rno01RYVV92kTXrY2cTfiP9WQ79N82fUf0J1ONo0fNANfIn47+/0GvptnRl1N9ACuxDb
XhbSG/GjGXtpq8vqbkBqmXPrbqABPkKaCYl4Dn6+Tctf0PSeRXzf7rPxw0+SIq4EzgrWzic9dElT
MABM75kVakvc9EeShqXKObTKubsTmvzIyCYYJf6s+ptJD8xR3I+AH/Txvz8IWDCgXiSApaR953v1
JOLnEME5wPXA/oHakzP3UhwDwNQeS3xryX8jrWJV3Pmk64BSW83BAFDFGGkx4HsDtYcD80ibA2kL
vAQwtRMr1H41WxeS1F1fCdaN4DqAKRkApnZ0sG4ZcFHORiSpo64AbgjWRs/hnWAAmFr0zfN90i2A
kqTqzgnWdXkTtmkZACa3O/EFZT/M2YgkdVw/i4EnelTWLgpjAJjc4gq1/5WtC0nSBcG6HYA9cjZS
EgPA5A4O1q0Crs7ZiCR13C2kB7JFRM/lxTMATO7AYN2VpIffSJLyuSRYd1DWLgpiAJjcwmDdtVm7
kCQBXBes2zNrFwUxAExuXrDu+qxdSJIAbgzWzc/aRUEMAJOLLhxZkrMJSRIQ3wvAADAJA8Dkdg7W
RReqSJImFz237pi1i4IYACa3bbDunqxdSJIA7g7WRc/lxTMAbNkIsHWwdnnORiRJQPzLlQFgEgaA
LduK+O9mbc5GJEkArAnWzc3aRUEMAPm5B4Ak5efj1TMzAORnAJCk/Dy3ZmYAyG+07gYkqUAjdTdQ
GgPAlq0h/jjf6OJBSdLktgnWrc7aRUEMAFu2gfRQn4jom1SSNLnol6sHsnZREAPA5KJvGm85kaT8
ogFgZdYuCmIAmNx9wbrds3YhSQLYLVh3f9YuCmIAmNwdwbroQ4QkSZNbEKxblrWLghgAJhd900Qf
IiRJmlz0y5UBYBIGgMlF3zQ+e1qS8ovOAERnc4tnAJjcTcG6/bN2IUmC+Ln11qxdFMQAMLmrg3UH
Ze1CkgTxc+sNWbsoiAFgctcE6/YAdsjZiCR13AzggGDtjTkbKYkBYHLXE997enHORiSp4/Yi9lS/
5fiI9kkZACa3DlgSrH1Uxj4kqeseHay7KmsXhTEATC26DsAAIEn5RAPAlVm7KIwBYGrRABB9s0qS
/rejgnW/ytpFYQwAU4tOHx0GzM7ZiCR1WHRW1RmAKRgApnZhsG4O8JicjUhSRx1AbBfA9cDFmXsp
igFgar8ivoL08TkbkaSOip5LL8cHAU3JADC1DcDPg7UGAEmqLnou/VnWLgpkAJhe9E10PDAzZyOS
1EEnBusuyNpFgQwA0/tpsG57YH7ORiSpY+YCi4K1BoBpGACm93PiOwLum7MRSeqYRcG6ZfgMgGkZ
AKa3krSYJOKYnI1IUsccG6w7P2sXhTIA9CZ6GcAAIElxRwfrordwd4oBoDfRAHAiMJKzEUnqkCcG
66Ln7E4xAPTmJ8G6PYDDczYiSR2xEDgkULcWuCRzL0UyAPRmKfEFJafkbESSOuLUYN3FpBCgaRgA
ehddVPLsrF1IUjdEz51O//fIANC77wbrjgX2ydmIJBVuR+DkYO33czZSMgNA775DbD+AEeC5mXuR
pJL9H2JPVH2A+JqtzjEA9G458amlF+VsRJIK94Jg3Xfx+n/PDAD9+Uaw7jHEn2ctSV2yCHhKsPas
jH0UzwDQny+TnjEd8dqcjUhSoV5D7LNpDfD1zL0UzQDQn6XAucHaFwPb5WtFkoozCrw8WPtN4L58
rZTPANC/LwbrtgV+P2cjklSY5wALgrX/mrORLjAA9O9rwOpg7TtICVeStKkR4F3B2uXAtzP20gkG
gP7dT1oLELE/8MKMvUhSKZ4KPDpY+8+4+r9vBoCYv6tQ+yf4e5ekzb0nWLcBODNnI13hB1HML4EL
grWHkK5zSZKSk4ATgrXfBa7O2EtnGADiTq9Q+0FgVq5GJKnFRoAPV6g/I1cjXWMAiPsqcHuw9lDg
jzL2Iklt9WLgd4K1NwDfythLpxgA4tYBf12h/oPAvEy9SFIbbQt8rEL9XwJjmXrpHANANWcAdwRr
t6fatJcktd37iN/3fwvw2Yy9dI4BoJqVwGkV6l9Jek6AJHXNAcCbK9R/lDQTqyADQHWfBJYFa2cA
nwPmZutGkppvBvCPwJxgvd/+MzAAVLcS+KsK9Y8gJVlJ6oo/AZ5Qof7PceOfygwAeZwB3Fyh/s3A
qZl6kaQmOwb40wr11wKfydRLpxkA8lgFvLVC/QhpK8s98rQjSY20DfB5qu2D8hb89p+FASCfrwHf
q1C/O+ma2EiediSpcT4BHFyh/hv40J9sDAB5vZFqyfQZpGtjklSalwOvqlC/GvjjPK0IDAC5XQv8
TcXX+DDwexl6kaSmOIHqD+z5S9LOf8rEAJDfnwG3VqgfAf4J9weQVIaDgP9P/JY/gBuBv8jTjjYy
AOT3AGmDnw0VXmNb4DukJwdKUlstID2tb9cKr7EBeB3pEoAyMgAMxvdJC/qq2BU4G9irejuSNHQ7
kxZGL6r4OqdTbYG1JmEAGJy3U/161d7AucA+lbuRpOHZDfgBaaOzKq4B3l29HW2JAWBwVgAvoPpe
1fsB55H2zZakptud9OF/ZMXXWQu8iLTPigbAADBYFwPvzPA6ewM/Bh6V4bUkaVD2B34CHJbhtd4G
XJLhdTQJA8Dg/S3wHxleZwEpBDw1w2tJUm6PBS4grfqv6ivA32d4HU3BADB4G4CXAVdkeK3tSDth
vSnDa0lSLi8Afki69l/VpaQ7qTRgBoDheIC0y9+dGV5rlP+ZVdghw+tJUtQo8DHgS+R5rPndwHNJ
T1nVgBkAhucm4HnAmkyv92zgZ8AjM72eJPVjH9IC5XeR5xkmq4Fn4m5/Q2MAGK7zSFNlD2V6vUNJ
i2Q+AMzM9JqSNJ3nA78Ejsv0euuBF5O+1GhIDADDl/sa/izg/aSVt+4cKGmQ9gS+SVqkt1PG1309
eRZLqw8GgHqcSf7NLY4DLgNOIy0WlKRcZpNuaf4N8PTMr/0Oqu+cKrXOu0l3CeQeS0l3HjTlssDz
if0cH6qjWSmjDxF77z+/jmYn8XTgagZzrnr7EH8ObcYZgHp9jMFsczkf+BxwJWnNgX9nSf06CTif
NOWf497+zb0d+KsBvK565AdD/f6CdP1r/QBeezHwZdJ9tX9AmsaTpMmMAE8BfkTazvf4Afwb64HX
Ah8fwGurDwaAZjiTdO/roB53eRjwL8DNpFmHhQP6dyS107akD+XLSY/vfcKA/p21wAvxmr/0vzyO
tFnQIK61TRxrSStunwPMGcLP5RoAdVWT1wDMAE4kfRjfG+yzn3EHg5lRkIqxiHR/7aAPxo1jOekE
8FTy7OS1JQYAdVXTAsAM4BjSTOBNwd4i4xekh5pJmsbWpK01h3VwbhyrgO+Q9ilYTJ7dvcAAoO5q
QgDYHfh90mXAO4L9VBlfYHBfLlTBaN0NaItWkQ7Y80n39Q/r4JkLnDo+AO4BLpwwLiXP8wwkDcZs
0vbgx5GezvdY4ICaellFeqTvmTX9+5qGAaC5NpAeh3ku8K/A4TX0sDPwtPGx0T2kzUCuGv/Pm0n7
Dtw+/p+rhtyj1DU7k271nU+aVl88Pg4B9qUZ+39cSvoSc1XdjWhyuaZ4NVhbAR8B3kwzDu6prCDN
EqwmPfjoftIJ64jAa/0G+HW+1qShO5T04dyv80mhekfSQt2tgV2AeaTzQVM9BPw/4H2kxcZqMANA
uxwJ/ANwdN2NSNJmLiXdSnhR3Y2oN03/NqlN3Q58lvSt+njc2EdS/VaQdjR9NXBrzb2oDwaA9hkD
LiDNBGwFHIUbOkkavjHSnQXPAs4hrVtSi3gJoP0Wk241atLDQySV7RzgrcAVdTeiOANAOR4F/DFp
5a0zO5JyGwO+TVqQfGHNvSgDA0B5DiA9X/sVwKyae5HUfg+SHir2MbwrpygGgHLNB15KWpW7X829
SGqfW4EvAmeQ9vtQYQwA5ZtBerzna4CnM5yH/0hqpzXAN0nPBzmHNO2vQhkAumVr4EmkBYPPJj0C
VFK3rSF92P878HXSbcbqAANAd21L2uL3yaRQsG+97UgaohuAHwDfJy3sW1lvO6qDAUAb7QecDDyB
9LjQ/WvtRlIuG4DrSTv0/Yj0bf/GWjtSIxgANJkdSZsMbRyLSXcYbF1nU5KmtAq4jvQQnl+Mj0uA
e+tsSs1kAFC/FpCCwP6kywZ7ALuNj10nDEl53TU+7hz/zzvGx42kD/3rSU/klHpiANCgjALbjf/3
ecBek/zv1pBOZlLXzGPyR7LfQnr2B6S99h8aSkeSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmS
JEmSJEmSJEmSJEnq3X8DhLrgrc2at60AAAAASUVORK5CYII=
"
    />
  </svg>
);

const CarSeatIcon = React.forwardRef(SvgComponent);
export default CarSeatIcon;
