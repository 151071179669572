import { useCallback, useMemo, useState } from 'react';
import { api } from '../../utils/api';
import { useMountedState } from '../../hooks/use-mounted-hook';

export const ACCOUNT_SETTINGS_SCHEMA = {
  formulaOne: null,
  eurotax: {
    clientId: 'webservice',
    username: null,
    password: null,
  },
};

export const useAccountSettings = () => {
  const isMounted = useMountedState();
  const [state, setState] = useState({
    error: null,
    isLoadingSettings: false,
    isSavingSettings: false,
    remoteSettings: undefined,
    brands: [],
    isLoadingBrands: false,
    brandModels: [],
    isLoadingBrandModels: false,
  });

  const operations = useMemo(() => ['getSettings', 'postSettings', 'getBrands', 'getBrandModels'], []);

  const setStartOperation = useCallback(
    (operation) => {
      if (operations.includes(operation) && isMounted()) {
        setState((s) => ({
          ...s,
          [operation]: true,
        }));
      }
    },
    [setState, operations, isMounted],
  );

  const setStopOperation = useCallback(
    (operation, error) => {
      if (operations.includes(operation) && isMounted()) {
        setState((s) => ({
          ...s,
          [operation]: false,
          error: error || null,
        }));
      }
    },
    [setState, operations, isMounted],
  );

  const setSuccessOperation = useCallback(
    (operation, key = 'data', value) => {
      if (operations.includes(operation)) {
        setState((s) => ({
          ...s,
          [key]: value,
          [operation]: false,
          error: null,
        }));
      }
    },
    [setState, operations],
  );

  const getSettings = useCallback(async () => {
    const currentOperation = 'getSettings';
    setStartOperation(currentOperation);
    try {
      const params = {};
      const response = await api.get('/account-settings', {
        params,
      });
      if (response.data === null) {
        throw new Error('no data received');
      }
      setSuccessOperation(currentOperation, 'remoteSettings', response.data.accountSettings);
    } catch (e) {
      const message = e.response?.data?.message || e.message || 'error during getting setting';
      setStopOperation(currentOperation, e);
      throw new Error(message);
    }
  }, [setStartOperation, setStopOperation, setSuccessOperation]);

  const postSettings = useCallback(
    async (accountSchema) => {
      const currentOperation = 'postSettings';
      setStartOperation(currentOperation);
      try {
        const params = { accountSettings: accountSchema };
        const response = await api.post('/account-settings', {
          params,
        });
        if (response.data === null) {
          throw new Error('no data received');
        }
        setSuccessOperation(currentOperation, 'remoteSettings', response.data.accountSettings);
      } catch (e) {
        const message = e.response?.data?.message || e.message || 'error during getting setting';
        setStopOperation(currentOperation, e);
        throw new Error(message);
      }
    },
    [setStartOperation, setStopOperation, setSuccessOperation],
  );

  return {
    isLoadingSettings: state.isLoadingSettings,
    isSavingSettings: state.isSavingSettings,
    getSettings,
    postSettings,
    remoteSettings: state.remoteSettings,
  };
};

export default useAccountSettings;
