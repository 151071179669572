import React, { Fragment, createContext, useState, useCallback, useContext, useRef } from 'react';
import classnames from 'classnames';
import { useIsOverflow } from './use-is-overflow';
import { Modal } from 'react-bootstrap';
import CrossIcon from '../assets/icons/cross-icon';

import './use-dialog.scss';

const DialogContext = createContext(undefined);

export const DialogProvider = ({ children }) => {
  // const [dialogData, setDialogData] = useState({ open: false });
  const [dialogData, setDialogData] = useState({ open: false, children: undefined, body: undefined, actions: [] });

  const handleModalClose = () => setDialogData((state) => ({ ...state, open: false }));

  const refDialogBody = useRef();
  const isDialogBodyOverflow = useIsOverflow(refDialogBody, () => {});

  return (
    <DialogContext.Provider value={{ setDialogData }}>
      {children}
      <Modal
        show={dialogData.open}
        onHide={handleModalClose}
        dialogClassName={classnames(['light-modal-dialog', 'use-dialog', dialogData.dialogClassName])}
        backdropClassName={'light-modal-backdrop'}
        centered
        scrollable={true}
      >
        <Modal.Header
          className={classnames('use-dialog-header', dialogData.headerClassName, {
            'is-with-modal-body-overflow': isDialogBodyOverflow,
          })}
        >
          <Modal.Title className={classnames('use-dialog-title-wrapper', dialogData.titleWrapperClassName)}>
            <div className={classnames('use-dialog-title', dialogData.titleClassName)}>{dialogData.title}</div>
          </Modal.Title>
          <div className={'light-modal-close'} onClick={handleModalClose}>
            <CrossIcon width={'10px'} height={'10px'} />
          </div>
        </Modal.Header>
        <Modal.Body>{dialogData.body}</Modal.Body>
        <Modal.Footer className={classnames('use-dialog-footer')}>
          {(dialogData.actions || []).map((a, index) => {
            return <Fragment key={index}>{a.children}</Fragment>;
          })}
        </Modal.Footer>
      </Modal>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const { setDialogData } = useContext(DialogContext);

  const showDialog = useCallback((data) => setDialogData({ open: true, ...data }), [setDialogData]);
  const hideDialog = useCallback(() => setDialogData({ open: false }), [setDialogData]);

  return {
    showDialog,
    hideDialog,
  };
};

export default useDialog;
