import React, { Suspense, lazy } from 'react';
import { Col, Row } from 'react-bootstrap';
import { isBrowser, isMobile } from 'react-device-detect';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth } from '../auth';
import AppLoading from '../components/app-loading';
import BaseLayout from '../components/layouts';
import { NEW_ROLES } from '../utils/constants';
import {
  checkAppraisalsConfigurator,
  checkAppraisalsConfirm,
  checkAppraisalsOperations,
  checkAppraisalsRead,
  checkContactCenter,
  checkThirdFlavorFeaturesEnabled,
  checkDatahubFeaturesEnabled,
} from '../utils/roles';

const Home = lazy(() => import('./home'));
const Login = lazy(() => import('./login'));
const Signup = lazy(() => import('./signup'));
const Logout = lazy(() => import('./logout'));
const Monitoring = lazy(() => import('./monitoring'));
const Todo = lazy(() => import('./todo'));
const TodoFromAds = lazy(() => import('./todo-ads'));
const Overview = lazy(() => import('./overview'));
const OverviewSoldAnalysis = lazy(() => import('./overview-sold-analysis'));
const OverviewOfferingAnalysis = lazy(() => import('./overview-offering-analysis'));
const Search = lazy(() => import('./search'));
const EvaluationHistory = lazy(() => import('./evaluation-history'));
const UserManagement = lazy(() => import('./user-management'));
const AccountManagement = lazy(() => import('./account-management'));
const ChangePassword = lazy(() => import('./change-password'));
const ResetPassword = lazy(() => import('./reset-password'));
const UserMonitoring = lazy(() => import('./user-monitoring'));
const EventUserLogs = lazy(() => import('./event-user-logs'));
const UserLogs = lazy(() => import('./user-logs'));
const Ads = lazy(() => import('./ads'));
const StockAnalysis = lazy(() => import('./stock-analysis'));
const VehicleFlavor = lazy(() => import('./vehicles-second-flavor'));
const PositioningAnalysis = lazy(() => import('./positioning-analysis'));
const UserSettings = lazy(() => import('./user-settings'));
const PickupPriceSettings = lazy(() => import('./pickup-price-settings'));
const AppraisalsSystemConfiguration = lazy(() => import('./appraisals-system-configuration'));
const AppraisalsInformationGathering = lazy(() => import('./appraisals-information-gathering'));
const AppraisalsConfirm = lazy(() => import('./appraisals-confirm'));
const AppraisalsContactCenter = lazy(() => import('./appraisals-contact-center'));
const Appraisals = lazy(() => import('./appraisals'));
const AppraisalsConfirmed = lazy(() => import('./appraisals-confirmed'));
const AppraisalsMobile = lazy(() => import('./appraisals/mobile/appraisals-mobile'));
const AppraisalsInformationGatheringMobile = lazy(
  () => import('./appraisals-information-gathering/mobile/information-gathering-mobile'),
);
const AccountSettings = lazy(() => import('./account-settings'));
const VehicleChanneling = lazy(() => import('./vehicles-channeling'));
const Events = lazy(() => import('./events'));
const ContactCenter = lazy(() => import('./contact-center/contact-center'));
const ContactCenterWidget = lazy(() => import('./contact-center-widget/contact-center-widget'));
const Marketplace = lazy(() => import('./marketplace/marketplace'));
const VehicleOverview = lazy(() => import('./third-flavor/vehicle-overview'));
const EventsOverview = lazy(() => import('./third-flavor/events'));
const DataHub = lazy(() => import('./data-hub'));
const DatahubSettings = lazy(() => import('./datahub-settings'));
const EventsAdministration = lazy(() => import('./events-administration'));

export default function Routes() {
  const isClientFromDesktop = isBrowser;
  const isClientFromMobile = isMobile;
  return (
    <Suspense fallback={<AppLoading />}>
      <Switch>
        {/* <Route exact path={"/"}><Home /></Route> */}
        <Route exact path={'/login'}>
          <Login />
        </Route>
        <Route exact path={'/register'}>
          <Signup />
        </Route>
        <Route exact path={'/reset-password/:token/:user'}>
          <ResetPassword />
        </Route>
        {/*
                <AuthRoute exact path={"/monitoring"}><Monitoring /></AuthRoute>
                <OperatorAuthRoute exact path={"/todo"}><Todo /></OperatorAuthRoute>
                <OperatorAuthRoute exact path={"/overview/:id"}><Overview /></OperatorAuthRoute>
                <SuperUserRoute exact path={"/search"}><Search /></SuperUserRoute>
                <OperatorAuthRoute exact path={"/evaluation-history"}><EvaluationHistory /></OperatorAuthRoute>
                <AdminRoute exact path={"/user-management"}><UserManagement /></AdminRoute>
                <AuthRoute exact path={"/change-password"}><ChangePassword /></AuthRoute>
                <AuthRoute exact path={"/logout"}><Logout /></AuthRoute>
                */}
        <HomeRoute exact path={'/'}></HomeRoute>
        {/* INFO react router conditional rendering is difficult to accomodate with customized inherited route components, */}
        {/* thinking to manage single per route condition for including */}

        <DataMonitorAuthRoute exact path={'/monitoring'}>
          {isClientFromDesktop && <Monitoring />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </DataMonitorAuthRoute>
        <CarsToPriceAuthRoute exact path={'/todo'}>
          {isClientFromDesktop && <Todo />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </CarsToPriceAuthRoute>
        <CarsToPriceAuthRoute exact path={'/tododriver'}>
          {isClientFromDesktop && <Todo segment="driver" />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </CarsToPriceAuthRoute>
        <CarsToPriceFromAdsAuthRoute exact path={'/todo-ads'}>
          {isClientFromDesktop && <TodoFromAds />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </CarsToPriceFromAdsAuthRoute>
        <StockAnalysisAuthRoute
          path={['/stock-analysis-:type/vehicle-:id', '/stock-analysis-:type/state-:uuid', '/stock-analysis-:type']}
        >
          {isClientFromDesktop && <StockAnalysis />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </StockAnalysisAuthRoute>
        <PositioningAnalysisAuthRoute
          path={[
            '/positioning-analysis-:type/vehicle-:id',
            '/positioning-analysis-:type/search-:id',
            '/positioning-analysis-:type/state-:uuid',
            '/positioning-analysis-:type',
          ]}
        >
          {isClientFromDesktop && <PositioningAnalysis />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </PositioningAnalysisAuthRoute>
        {isClientFromDesktop && (
          <CarsToPriceOrCarsToPriceFromAdsOrOpenSearchEventsAuthRoute
            path={['/overview/sold-analysis', '/overview/:id/sold-analysis']}
          >
            <OverviewSoldAnalysis />
          </CarsToPriceOrCarsToPriceFromAdsOrOpenSearchEventsAuthRoute>
        )}
        {isClientFromDesktop && (
          <CarsToPriceOrCarsToPriceFromAdsOrOpenSearchEventsAuthRoute
            path={['/overview/offering-analysis', '/overview/:id/offering-analysis']}
          >
            <OverviewOfferingAnalysis />
          </CarsToPriceOrCarsToPriceFromAdsOrOpenSearchEventsAuthRoute>
        )}
        {isClientFromDesktop && (
          <CarsToPriceOrCarsToPriceFromAdsAuthRoute exact path={'/overview/:id'}>
            <Overview />
          </CarsToPriceOrCarsToPriceFromAdsAuthRoute>
        )}
        {isClientFromDesktop && (
          <AdsAuthRoute exact path={['/ads-:type']}>
            <Ads />
          </AdsAuthRoute>
        )}
        {isClientFromDesktop && (
          <VehicleFlavorAuthRoute exact path={'/vehicles-second-flavor'}>
            <VehicleFlavor />
          </VehicleFlavorAuthRoute>
        )}
        {isClientFromDesktop && (
          <VehicleFlavorAuthRoute exact path={'/overview-second-flavor/:id'}>
            <Overview secondFlavor={true} />
          </VehicleFlavorAuthRoute>
        )}
        {isClientFromDesktop && (
          <VehicleChannelingAuthRoute key={'VehicleChanneling'} exact path={'/vehicles-channeling'}>
            <VehicleChanneling />
          </VehicleChannelingAuthRoute>
        )}
        {isClientFromDesktop && (
          <VehicleChannelingAuthRoute key={'OverviewVehicleChanneling'} exact path={'/overview-channeling/:id'}>
            <Overview isChanneling={true} />
          </VehicleChannelingAuthRoute>
        )}

        {isClientFromDesktop && (
          <EventsAuthRoute key="events-overview" exact path={'/overview-event/:id'}>
            <Overview isEvents={true} />
          </EventsAuthRoute>
        )}
        <OpenSearchAuthRoute path={['/search/state-:uuid', '/search']}>
          {isClientFromDesktop && <Search />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </OpenSearchAuthRoute>
        {isClientFromDesktop && (
          <EventsAuthRoute key="events" path={['/events']}>
            <Events />
          </EventsAuthRoute>
        )}
        {isClientFromDesktop && (
          <EventsAuthRoute
            key="events-evaluation-history"
            exact
            path={'/evaluation-history/:historyDomain(events-vehicle-list)'}
          >
            <Events isHistory={true} />
          </EventsAuthRoute>
        )}
        {isClientFromDesktop && (
          <EvalHistoryAuthRoute
            exact
            path={
              '/evaluation-history/:historyDomain(priced-vehicle-list|driver-priced-vehicle-list|estimated-price-vehicle-list|pickedup-vehicle-list)?'
            }
          >
            <EvaluationHistory />
          </EvalHistoryAuthRoute>
        )}
        {isClientFromDesktop && (
          <EvalHistoryEstimatedPriceVehicleListAuthRoute
            exact
            path={'/evaluation-history/:historyDomain(estimated-price-vehicle-list)'}
          >
            <EvaluationHistory />
          </EvalHistoryEstimatedPriceVehicleListAuthRoute>
        )}
        {isClientFromDesktop && (
          <EvalHistoryPickedupVehicleListAuthRoute
            exact
            path={'/evaluation-history/:historyDomain(pickedup-vehicle-list)'}
          >
            <EvaluationHistory />
          </EvalHistoryPickedupVehicleListAuthRoute>
        )}
        {isClientFromDesktop && (
          <EvalHistorySecondFlavorAuthRoute
            exact
            path={'/evaluation-history/:historyDomain(priced-vehicle-list-second-flavor)'}
          >
            <EvaluationHistory />
          </EvalHistorySecondFlavorAuthRoute>
        )}
        {isClientFromDesktop && (
          <EvalHistoryChannelingAuthRoute
            key={'EvalHistoryChanneling'}
            exact
            path={'/evaluation-history/:historyDomain(priced-vehicle-list-channeling)'}
          >
            <VehicleChanneling isHistory={true} />
          </EvalHistoryChannelingAuthRoute>
        )}

        <AppraisalsReadAuthRoute exact path={'/evaluations/appraisals/(list)?'}>
          {isClientFromDesktop && <Appraisals />}
          {isClientFromMobile && <AppraisalsMobile />}
        </AppraisalsReadAuthRoute>
        <AppraisalsReadAuthRoute path={'/evaluations/appraisals/:uuid/information-gathering'}>
          {isClientFromDesktop && <AppraisalsInformationGathering />}
          {isClientFromMobile && <AppraisalsInformationGatheringMobile />}
        </AppraisalsReadAuthRoute>
        <AppraisalsConfirmAuthRoute path={'/evaluations/appraisals/:uuid/confirm'}>
          {isClientFromDesktop && <AppraisalsConfirm />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </AppraisalsConfirmAuthRoute>
        <AppraisalsOperationsAuthRoute path={'/evaluations/appraisals/contact-center'}>
          {isClientFromDesktop && <AppraisalsContactCenter />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </AppraisalsOperationsAuthRoute>
        <AppraisalsReadAuthRoute exact path={'/evaluations/appraisals/confirmed'}>
          {isClientFromDesktop && <AppraisalsConfirmed />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </AppraisalsReadAuthRoute>

        {/* MARKETPLACE */}
        <MarketplaceUserAuthRoute exatc path={'/marketplace'}>
          {isClientFromDesktop && <Marketplace />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </MarketplaceUserAuthRoute>

        <ContactCenterAuthRoute exact path={'/contact-center'}>
          {isClientFromDesktop && <ContactCenter />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </ContactCenterAuthRoute>
        <ContactCenterAuthRoute exact path={'/contact-center-widget'}>
          {isClientFromDesktop && <ContactCenterWidget />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </ContactCenterAuthRoute>
        <ThirdFlavorFeaturesAuthRoute path={'/third-flavor/vehicle-overview'}>
          {isClientFromDesktop && <VehicleOverview />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </ThirdFlavorFeaturesAuthRoute>
        <ThirdFlavorFeaturesEventsAnyRoleAuthRoute path={'/third-flavor/events'}>
          {isClientFromDesktop && <EventsOverview />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </ThirdFlavorFeaturesEventsAnyRoleAuthRoute>
        <DataHubRoleAuthRoute path={'/data-hub/:type'}>{isClientFromDesktop && <DataHub />}</DataHubRoleAuthRoute>
        <DataHubDriverRoleAuthRoute path={'/data-hub-driver/:type'}>
          {isClientFromDesktop && <DataHub segment="driver" />}
        </DataHubDriverRoleAuthRoute>

        <UserAdminAuthRoute exact path={'/user-management'}>
          {isClientFromDesktop && <UserManagement />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </UserAdminAuthRoute>
        <AccountAdminAuthRoute exact path={'/account-management'}>
          {isClientFromDesktop && <AccountManagement />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </AccountAdminAuthRoute>
        <EventsAdministratorAuthRoute exact path={'/events-administration'}>
          {isClientFromDesktop && <EventsAdministration />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </EventsAdministratorAuthRoute>
        <UserMonitoringAdminAuthRoute exact path={'/user-monitoring'}>
          {isClientFromDesktop && <UserMonitoring />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </UserMonitoringAdminAuthRoute>
        <EventsAdministratorAuthRouteOrViewUsersEventLog exact path={'/event-user-logs'}>
          {isClientFromDesktop && <EventUserLogs />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </EventsAdministratorAuthRouteOrViewUsersEventLog>
        <ViewUsersLogRouteWithAccountGroups exact path={'/user-logs'}>
          {isClientFromDesktop && <UserLogs />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </ViewUsersLogRouteWithAccountGroups>

        <AuthRoute exact path={'/change-password'}>
          <ChangePassword />
        </AuthRoute>
        <AuthRoute exact path={'/user-settings'}>
          <UserSettings />
        </AuthRoute>
        <PickupPriceSettingsAuthRoute exact path={'/pickup-price-settings'}>
          {isClientFromDesktop && <PickupPriceSettings />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </PickupPriceSettingsAuthRoute>
        <AppraisalsConfiguratorAuthRoute exact path={'/appraisals-system-configuration/:sub(levels|tree)?'}>
          {isClientFromDesktop && <AppraisalsSystemConfiguration />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </AppraisalsConfiguratorAuthRoute>
        <AuthRoute exact path={'/account-settings'}>
          {isClientFromDesktop && <AccountSettings />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </AuthRoute>
        <DatahubFeaturesManageRoleAuthRoute path={'/datahub-settings'}>
          {isClientFromDesktop && <DatahubSettings />}
          {isClientFromMobile && <NoAccessUpgradeRequired />}
        </DatahubFeaturesManageRoleAuthRoute>
        <AuthRoute exact path={'/logout'}>
          <Logout />
        </AuthRoute>
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </Suspense>
  );
}

// general Function to show routes only if the user has the rights to
export function createAuthRoute(check, landing = false) {
  return function AuthRoute({ children, ...rest }) {
    const { user } = useAuth();
    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (!user) {
            return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
          }
          if (landing && check(user)) {
            return <Home user={user} />;
          }
          if (check(user)) {
            return children;
          }
          return <NoPermission />;
        }}
      />
    );
  };
}

export const checkAnyOfUserPages = (user = {}, allowedPages = []) => {
  const { pages = [] } = user || {};
  // Return true if the user has at least one of the allowed pages
  return allowedPages.some((role) => pages.includes(role));
};

export function checkAnyOfUserRoles(user = {}, allowedRoles = []) {
  const { roles = [] } = user || {};
  return allowedRoles.some((role) => roles.includes(role));
}

export const checkArvalAdminEventsAdministrator = (user = {}, allowedRoles = []) => {
  const { pages = [] } = user;
  return allowedRoles.some((role) => pages.includes(role));
};

export const checkDataMonitorTotalAds = (user = {}) => {
  const { datamonitorTotalAdsEnabled = false } = user;

  if (datamonitorTotalAdsEnabled) {
    return true;
  }
  return false;
};

export const checkAppraisalsManagement = (user = {}) => {
  const { mobileClassAFeaturesEnabled = false } = user;
  if (mobileClassAFeaturesEnabled) {
    return true;
  }
  return false;
};
export const checkPickupPriceSettings = (user = {}) => {
  const { roles } = user;
  const allowedRoles = ['UserAdministrator', 'AccountAdministrator', 'EvaluationsReviewer'];
  const rolesPass = allowedRoles.some((role) => roles.includes(role));
  if (rolesPass) {
    return true;
  }
  return false;
};

export const checkVehiclestopriceSecondFlavorEnabled = (user = {}) => {
  const { vehiclestopriceSecondFlavorEnabled = false } = user || {};

  if (vehiclestopriceSecondFlavorEnabled) {
    return true;
  }
  return false;
};

export const checkVehiclestopriceChannelingEnabled = (user = {}, allowedRoles = []) => {
  const { vehiclestopriceChannelingEnabled = false, pages = [] } = user || {};

  if (vehiclestopriceChannelingEnabled && allowedRoles.some((role) => pages.includes(role))) {
    return true;
  }
  return false;
};

export const checkUserHasAccountGroups = (user = {}) => {
  const { accountGroups = [] } = user;
  return accountGroups.length > 0;
};

const AuthRoute = createAuthRoute(() => true); // any user role is able to access
// export const OperatorAuthRoute = createAuthRoute(user => user.roles.find(f => f === ROLES.OPERATOR || f === ROLES.ADMIN))
// const SuperUserRoute = createAuthRoute(user => user.roles.find(f => f === ROLES.SUPER_USER || f === ROLES.OPENSEARCH || f === ROLES.ADMIN))
// const AdminRoute = createAuthRoute(user => user.roles.find(r => r === ROLES.ADMIN))
//
export const HomeRoute = createAuthRoute((user) => user, true);
export const DataMonitorAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.DATA_MONITOR]));
export const CarsToPriceAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.CARS_TO_PRICE]));
export const CarsToPriceFromAdsAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.CARS_TO_PRICE_FROM_ADS]),
);
export const CarsToPriceOrCarsToPriceFromAdsAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.CARS_TO_PRICE, NEW_ROLES.CARS_TO_PRICE_FROM_ADS]),
);
export const OpenSearchAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.OPEN_SEARCH]));
export const CarsToPriceOrOpenSearchAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.CARS_TO_PRICE, NEW_ROLES.OPEN_SEARCH]),
);
export const CarsToPriceOrCarsToPriceFromAdsOrOpenSearchEventsAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [
    NEW_ROLES.CARS_TO_PRICE,
    NEW_ROLES.CARS_TO_PRICE_FROM_ADS,
    NEW_ROLES.OPEN_SEARCH,
    NEW_ROLES.EVENTS,
  ]),
);
export const EvalHistoryAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.EVAL_HISTORY]));
export const UserAdminAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.USER_ADMINISTRATOR]));
export const AccountAdminAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.ACCOUNT_ADMINISTRATOR]),
);

export const ViewUsersLogRouteWithAccountGroups = createAuthRoute(
  (user) =>
    checkAnyOfUserPages(user, [NEW_ROLES.ACCOUNT_ADMINISTRATOR, NEW_ROLES.VIEW_USERS_LOG]) &&
    checkUserHasAccountGroups(user),
);

export const UserMonitoringAdminAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.USER_MONITORING]),
);
export const AdsAuthRoute = createAuthRoute((user) => checkDataMonitorTotalAds(user));
export const StockAnalysisAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.STOCK_ANALYSIS]));
export const PositioningAnalysisAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.POSITIONING_ANALYSIS]),
);
export const AppraisalsManagementAuthRoute = createAuthRoute((user) => checkAppraisalsManagement(user));
export const VehicleFlavorAuthRoute = createAuthRoute((user) => checkVehiclestopriceSecondFlavorEnabled(user));
export const EvalHistoryEstimatedPriceVehicleListAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.EVALUATIONS_REVIEWER, NEW_ROLES.EVALUATIONS_STOREMANAGER]),
);
export const EvalHistoryPickedupVehicleListAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.EVALUATIONS_REVIEWER]),
);
export const EvalHistorySecondFlavorAuthRoute = createAuthRoute(
  (user) => checkVehiclestopriceSecondFlavorEnabled(user) && checkAnyOfUserPages(user, [NEW_ROLES.EVAL_HISTORY]),
);
export const PickupPriceSettingsAuthRoute = createAuthRoute((user) => checkPickupPriceSettings(user));
export const VehicleChannelingAuthRoute = createAuthRoute((user) =>
  checkVehiclestopriceChannelingEnabled(user, [NEW_ROLES.CHANNELING]),
);
export const EvalHistoryChannelingAuthRoute = createAuthRoute((user) =>
  checkVehiclestopriceChannelingEnabled(user, [NEW_ROLES.CHANNELING]),
);
export const EventsAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.EVENTS]));
export const AppraisalsReadAuthRoute = createAuthRoute((user) => checkAppraisalsRead(user));
export const AppraisalsConfirmAuthRoute = createAuthRoute((user) => checkAppraisalsConfirm(user));
export const AppraisalsOperationsAuthRoute = createAuthRoute((user) => checkAppraisalsOperations(user));
export const AppraisalsConfiguratorAuthRoute = createAuthRoute((user) => checkAppraisalsConfigurator(user));
export const ContactCenterAuthRoute = createAuthRoute(
  (user) => checkContactCenter(user) || checkAnyOfUserPages(user, [NEW_ROLES.CONTACT_CENTER]),
);
export const MarketplaceUserAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.MARKETPLACE]));
export const EventsAdministratorAuthRoute = createAuthRoute((user) =>
  checkArvalAdminEventsAdministrator(user, [NEW_ROLES.EVENTS_ADMINISTRATOR]),
);
export const EventsAdministratorAuthRouteOrViewUsersEventLog = createAuthRoute((user) =>
  checkArvalAdminEventsAdministrator(user, [NEW_ROLES.EVENTS_ADMINISTRATOR, NEW_ROLES.VIEW_USERS_EVENT_LOG]),
);

export const ThirdFlavorFeaturesAuthRoute = createAuthRoute((user) => checkThirdFlavorFeaturesEnabled(user));

export const ThirdFlavorFeaturesEventsAnyRoleAuthRoute = createAuthRoute((user) =>
  checkThirdFlavorFeaturesEnabled(user, [NEW_ROLES.CHANNELING_EVENTSREAD, NEW_ROLES.CHANNELING_EVENTSMANAGE]),
);

export const ThirdFlavorFeaturesEventsReadRoleAuthRoute = createAuthRoute((user) =>
  checkThirdFlavorFeaturesEnabled(user, [NEW_ROLES.CHANNELING_EVENTSREAD]),
);

export const ThirdFlavorFeaturesEventsManageRoleAuthRoute = createAuthRoute((user) =>
  checkThirdFlavorFeaturesEnabled(user, [NEW_ROLES.CHANNELING_EVENTSMANAGE]),
);

export const DataHubRoleAuthRoute = createAuthRoute((user) => checkAnyOfUserPages(user, [NEW_ROLES.DATAHUB]));

export const DatahubFeaturesManageRoleAuthRoute = createAuthRoute((user) =>
  checkDatahubFeaturesEnabled(user, [NEW_ROLES.DATAHUBFEATURES_MANAGE]),
);

export const DataHubDriverRoleAuthRoute = createAuthRoute((user) =>
  checkAnyOfUserPages(user, [NEW_ROLES.DATAHUB_DRIVER]),
);

export { NEW_ROLES };

const centered = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export function NoMatch() {
  return (
    <BaseLayout>
      <Row style={centered}>
        <Col xs={'auto'} className={'mx-auto'}>
          <div className="text-center">
            <h1>Oops!</h1>
            <h2>404 - Page not found</h2>
            <div className="error-details">Sorry, we couldn&apos;t find the page you were looking for.</div>
          </div>
        </Col>
      </Row>
    </BaseLayout>
  );
}

export function NoPermission() {
  return (
    <BaseLayout testId="screen-no-permission">
      <Row style={centered}>
        <Col xs={'auto'} className={'mx-auto'}>
          <div className="text-center">
            <h1>Oops!</h1>
            <h2>403 - Access Forbidden</h2>
            <div className="error-details">Sorry, you currently have no access to this feature.</div>
          </div>
        </Col>
      </Row>
    </BaseLayout>
  );
}

export function NoAccessUpgradeRequired() {
  return (
    <BaseLayout>
      <Row style={centered}>
        <Col xs={'auto'} className={'mx-auto'}>
          <div className="text-center">
            <h1>Oops!</h1>
            <h2>402 - Upgrade required</h2>
            <div className="error-details">Sorry, you need an upgrade to access resource.</div>
          </div>
        </Col>
      </Row>
    </BaseLayout>
  );
}
