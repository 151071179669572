import { Spinner } from 'react-bootstrap';
import React from 'react';

import './grid-loading.scss';

export default function GridLoading() {
  return (
    <div data-testid={'grid-loading'} className={'grid-loading-container'}>
      <div className={'grid-loading-content'}>
        <Spinner className={'grid-loading-progress'} size={'sm'} animation={'grow'} />
      </div>
    </div>
  );
}
