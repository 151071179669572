import { useCallback, useMemo, useState } from 'react';
import { api } from '../utils/api';

export const useDatahub = () => {
  const [state, setState] = useState({
    error: null,
    isLoadingSettings: false,
    remoteSettings: undefined,
    isGettingAppraisal: false,
    isPatchingSettings: false,
  });

  const operations = useMemo(
    () => ['isLoadingSettings', 'isGettingSetups', 'isGettingFilters', 'isGettingAppraisal', 'isPatchingSettings'],
    [],
  );

  const setStartOperation = useCallback(
    (operation) => {
      if (operations.includes(operation)) {
        setState((s) => ({
          ...s,
          [operation]: true,
        }));
      }
    },
    [setState, operations],
  );

  const setStopOperation = useCallback(
    (operation, error) => {
      if (operations.includes(operation)) {
        setState((s) => ({
          ...s,
          [operation]: false,
          error: error || null,
        }));
      }
    },
    [setState, operations],
  );

  const setSuccessOperation = useCallback(
    (operation, key = 'data', value) => {
      if (operations.includes(operation)) {
        setState((s) => ({
          ...s,
          [key]: value,
          [operation]: false,
          error: null,
        }));
      }
    },
    [setState, operations],
  );

  const getSettings = useCallback(async () => {
    const currentOperation = 'isLoadingSettings';
    setStartOperation(currentOperation);
    try {
      const params = {};
      const response = await api.get('/data-hub/settings', {
        params,
      });
      if (response.data === null) {
        throw new Error('no data received');
      }
      const { datahubFeaturesSettings } = response.data;
      setSuccessOperation(currentOperation, 'remoteSettings', datahubFeaturesSettings);
      return response.data;
    } catch (e) {
      const message =
        e.response?.data?.error || e.response?.data?.message || e.message || 'error during getting settings';
      setStopOperation(currentOperation, e);
      throw new Error(message);
    }
  }, [setStartOperation, setStopOperation, setSuccessOperation]);

  const patchSetSettings = useCallback(
    async (setAppraisalPayload) => {
      const currentOperation = 'isPatchingSettings';
      setStartOperation(currentOperation);
      try {
        const response = await api.patch('/data-hub/settings', setAppraisalPayload);
        if (response.data === null) {
          throw new Error('no data received');
        }
        const { datahubFeaturesSettings } = response.data;
        setSuccessOperation(currentOperation, 'remoteSettings', datahubFeaturesSettings);
        return response.data;
      } catch (e) {
        const message =
          e.response?.data?.error || e.response?.data?.message || e.message || 'error during setting data';
        setStopOperation(currentOperation, e);
        throw new Error(message);
      }
    },
    [setStartOperation, setStopOperation, setSuccessOperation],
  );

  return {
    isLoadingSettings: state.isLoadingSettings,
    getSettings,
    remoteSettings: state.remoteSettings,
    isPatchingSettings: state.isPatchingSettings,
    patchSetSettings,
  };
};

export default useDatahub;
