import { createSlice } from '@reduxjs/toolkit';
import _set from 'lodash/set';

const initialFormulaOne = null;
const initialPickupPrice = null;
const initialDatahub = null;
const initialEurotax = { clientId: 'webservice', username: null, password: null };

export const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState: {
    formulaOne: initialFormulaOne,
    pickupPrice: initialPickupPrice,
    datahub: initialDatahub,
    eurotax: initialEurotax,
  },
  reducers: {
    setFormulaOne: (state, { payload: value }) => {
      state.formulaOne = value;
    },
    setEurotaxConfiguration: (state, { payload: { path, value } }) => {
      _set(state.eurotax, path, value);
    },
    setPickupPrice: (state, { payload: value }) => {
      state.pickupPrice = value;
    },
    setPickupPricePathValue: (state, { payload: { path, value } }) => {
      _set(state.pickupPrice, path, value);
    },
    setDatahub: (state, { payload: { path, value } }) => {
      if (path === undefined) {
        state.datahub = value;
      } else {
        _set(state.datahub, path, value);
      }
    },
  },
});

export const { setFormulaOne, setPickupPrice, setPickupPricePathValue, setDatahub, setEurotaxConfiguration } =
  accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;
