import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NEW_ROLES,
  checkAnyOfUserPages,
  checkAnyOfUserRoles,
  checkVehiclestopriceChannelingEnabled,
  checkVehiclestopriceSecondFlavorEnabled,
} from '../../routes/routes';
import { Dropdown, Nav } from 'react-bootstrap';
import { useAuth } from '../../auth';
import { Link } from 'react-router-dom';
import {
  checkAppraisalsBundledWithOtherFeatures,
  checkAppraisalsOperations,
  checkAppraisalsRead,
  checkAppraisalsSystemEnabled,
  checkContactCenter,
  checkContactCenterWidget,
  checkDataHubFeaturesEnabled,
  checkPickupPriceVisible,
  checkThirdFlavorFeaturesEnabled,
} from '../../utils/roles';
import { TFunction } from 'i18next';

type CommonMenuItem = {
  id: string;
  label: string;
  anyOfPages?: string[];
  anyOfRoles?: string[];
  noPages?: string[];
  noRoles?: string[];
  enabled?: boolean;
  check?: (user?: User) => boolean;
};

type MenuLink = CommonMenuItem &
  (
    | {
        path: string;
      }
    | {
        url: string;
      }
  );

type MenuItem =
  | MenuLink
  | (CommonMenuItem & {
      children: MenuLink[];
    });

const DropdownToggle = React.forwardRef<
  HTMLSpanElement,
  { children: React.ReactNode; onClick: (e: React.MouseEvent) => void; active: boolean }
>(({ children, onClick, active }, ref) => (
  <span
    style={active ? { color: '#2BB5AE', fontWeight: 600 } : { color: '#223239' }}
    role="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </span>
));
DropdownToggle.displayName = 'DropdownToggle';

function isWithEvaluationHistory(user?: User) {
  return checkAnyOfUserPages(user, [NEW_ROLES.EVAL_HISTORY]);
}

function isWithEvaluationAppraisals(user?: User) {
  return checkAppraisalsSystemEnabled(user) && checkAppraisalsRead(user) && !checkAppraisalsOperations(user);
}

function shouldDisplayAppraisalsFeature(user?: User) {
  return (
    (checkAppraisalsSystemEnabled(user) && checkAppraisalsBundledWithOtherFeatures(user)) ||
    !checkAppraisalsSystemEnabled(user)
  );
}

function isWithExtendedEvaluationHistory(user?: User) {
  return (
    user?.pickupPriceListingEvaluation ||
    user?.pickupPriceListingPickedup ||
    checkAnyOfUserPages(user, [NEW_ROLES.EVENTS]) ||
    checkVehiclestopriceChannelingEnabled(user, [NEW_ROLES.CHANNELING])
  );
}

function isDemoUser(user?: User) {
  return user?.accountCategory === 'DEMO';
}

const PAGES: {
  [key: string]: (t: TFunction) => MenuLink;
} = {
  MONITORING: (t: TFunction) => ({
    id: 'monitoring',
    anyOfPages: [NEW_ROLES.DATA_MONITOR],
    label: t('baseLayout.menuItems.monitoring'),
    path: '/monitoring',
  }),
  CARS_TO_PRICE: (t: TFunction) => ({
    id: 'todo',
    anyOfPages: [NEW_ROLES.CARS_TO_PRICE],
    label: t('baseLayout.menuItems.todo'),
    path: '/todo',
  }),
  CARS_TO_PRICE_DRIVER: (t: TFunction) => ({
    id: 'tododriver',
    anyOfPages: [NEW_ROLES.CARS_TO_PRICE_DRIVER],
    label: t('baseLayout.menuItems.todoDriver'),
    path: '/tododriver',
  }),
  CARS_TO_PRICE_FROM_ADS: (t: TFunction) => ({
    id: 'todo-from-ads',
    anyOfPages: [NEW_ROLES.CARS_TO_PRICE_FROM_ADS],
    label: t('baseLayout.menuItems.todoFromAds'),
    path: '/todo-ads',
  }),
  CARS_TO_PRICE_B2C: (t: TFunction) => ({
    id: 'vehicles-second-flavor',
    check: (user) => checkVehiclestopriceSecondFlavorEnabled(user),
    anyOfPages: [NEW_ROLES.CARS_TO_PRICE],
    label: t('baseLayout.menuItems.vehiclestopriceSecondFlavor'),
    path: '/vehicles-second-flavor',
  }),
  CARS_TO_PRICE_CHANNELING: (t: TFunction) => ({
    id: 'vehicles-channeling',
    check: (user) => checkVehiclestopriceChannelingEnabled(user, [NEW_ROLES.CHANNELING]),
    label: t('baseLayout.menuItems.vehiclestopriceChanneling'),
    path: '/vehicles-channeling',
  }),
  CARS_TO_PRICE_WEBAPP_VIDEO: () => ({
    id: 'vehicles-webapp-video',
    check: (user) => user?.account === 'AIR',
    label: 'Video WebApp',
    url: 'https://youtu.be/-L7bOKhn7zM',
  }),
  OPEN_SEARCH: (t: TFunction) => ({
    id: 'search',
    anyOfPages: [NEW_ROLES.OPEN_SEARCH],
    check: (user) => shouldDisplayAppraisalsFeature(user),
    label: t('baseLayout.menuItems.search'),
    path: '/search',
  }),
  EVENTS: (t: TFunction) => ({
    id: 'events',
    anyOfPages: [NEW_ROLES.EVENTS],
    label: t('baseLayout.menuItems.events'),
    path: '/events',
  }),
  EVENT_HISTORY: (t: TFunction) => ({
    id: 'events-history',
    anyOfPages: [NEW_ROLES.EVENTS],
    label: t('baseLayout.menuItems.evaluationHistoryEventsVehicleList'),
    path: '/evaluation-history/events-vehicle-list',
  }),
  VEHICLES_CHANNELING_HISTORY: (t: TFunction) => ({
    id: 'vehicles-channeling-history',
    check: (user) => checkVehiclestopriceChannelingEnabled(user, [NEW_ROLES.CHANNELING]),
    label: t('baseLayout.menuItems.evaluationHistoryPricedVehicleListChanneling'),
    path: '/evaluation-history/priced-vehicle-list-channeling',
  }),
  EXTENDED_EVALUATION_HISTORY_PRICED_VEHICLE_LIST: (t: TFunction) => ({
    id: 'evaluation-history',
    check: (user) => !isDemoUser(user) && shouldDisplayAppraisalsFeature(user) && isWithExtendedEvaluationHistory(user),
    anyOfPages: [NEW_ROLES.EVAL_HISTORY],
    noRoles: [NEW_ROLES.EVALUATIONS_STOREMANAGER],
    label: t('baseLayout.menuItems.evaluationHistoryPricedVehicleList'),
    path: '/evaluation-history/priced-vehicle-list',
  }),
  EXTENDED_EVALUATION_HISTORY_ESTIMATED_PRICE_VEHICLE_LIST: (t: TFunction) => ({
    id: 'evaluation-history-estimated-price-vehicle-list',
    check: (user) =>
      shouldDisplayAppraisalsFeature(user) &&
      isWithExtendedEvaluationHistory(user) &&
      !!user?.pickupPriceListingEvaluation,
    anyOfPages: [NEW_ROLES.EVAL_HISTORY],
    label: t('baseLayout.menuItems.evaluationHistoryEstimatedPriceVehicleList'),
    path: '/evaluation-history/estimated-price-vehicle-list',
  }),
  EXTENDED_EVALUATION_HISTORY_PICKEDUP_VEHICLE_LIST: (t: TFunction) => ({
    id: 'evaluation-history-pickedup-vehicle-list',
    check: (user) =>
      shouldDisplayAppraisalsFeature(user) &&
      isWithExtendedEvaluationHistory(user) &&
      !!user?.pickupPriceListingPickedup,
    anyOfPages: [NEW_ROLES.EVAL_HISTORY],
    label: t('baseLayout.menuItems.evaluationHistoryPickedupVehicleList'),
    path: '/evaluation-history/pickedup-vehicle-list',
  }),
  EXTENDED_EVALUATION_HISTORY_PRICED_VEHICLE_LIST_SECOND_FLAVOR: (t: TFunction) => ({
    id: 'evaluation-history-second-flavor-vehicle-list',
    check: (user) =>
      shouldDisplayAppraisalsFeature(user) &&
      isWithExtendedEvaluationHistory(user) &&
      checkVehiclestopriceSecondFlavorEnabled(user),
    anyOfPages: [NEW_ROLES.EVAL_HISTORY],
    label: t('baseLayout.menuItems.evaluationHistoryPricedVehicleListSecondFlavor'),
    path: '/evaluation-history/priced-vehicle-list-second-flavor',
  }),
  EVALUATION_HISTORY_DRIVER: (t: TFunction) => ({
    id: 'driver-evaluation-history',
    anyOfPages: [NEW_ROLES.EVAL_HISTORY_DRIVER],
    label: t('baseLayout.menuItems.evaluationHistoryDriver'),
    path: '/evaluation-history/driver-priced-vehicle-list',
  }),
  MARKETPLACE: (t: TFunction) => ({
    id: 'marketplace',
    anyOfPages: [NEW_ROLES.MARKETPLACE],
    label: t('baseLayout.menuItems.marketplace'),
    path: '/marketplace',
  }),
  STOCK_ANALYSIS: (t: TFunction) => ({
    id: 'stock-analysis',
    anyOfPages: [NEW_ROLES.STOCK_ANALYSIS],
    label: t('baseLayout.menuItems.stockAnalysis'),
    path: '/stock-analysis-overall',
  }),
  POSITIONING_ANALYSIS: (t: TFunction) => ({
    id: 'positioning-analysis',
    anyOfPages: [NEW_ROLES.POSITIONING_ANALYSIS],
    label: t('baseLayout.menuItems.positioningAnalysis'),
    path: '/positioning-analysis-overall',
  }),
  EVALUATIONS_APPRAISALS_NEW: (t: TFunction) => ({
    id: 'evaluations-appraisals-new',
    check: (user) => isWithEvaluationAppraisals(user),
    label: t('baseLayout.menuItems.appraisalsNewEntity'),
    path: '/evaluations/appraisals?new=true',
  }),
  EVALUATIONS_APPRAISALS: (t: TFunction) => ({
    id: 'evaluations-appraisals',
    check: (user) => isWithEvaluationAppraisals(user),
    label: t('baseLayout.menuItems.appraisalsListEntities'),
    path: '/evaluations/appraisals',
  }),
  EVALUATIONS_APPRAISALS_CONFIRMED: (t: TFunction) => ({
    id: 'evaluations-appraisals-confirmed',
    check: (user) => isWithEvaluationAppraisals(user),
    label: t('baseLayout.menuItems.appraisalsConfirmedEntities'),
    path: '/evaluations/appraisals/confirmed',
  }),
  CONTACT_CENTER_ADS: (t: TFunction) => ({
    id: 'contact-center',
    anyOfPages: [NEW_ROLES.CONTACT_CENTER],
    label: t('baseLayout.menuItems.contactCenter'),
    path: '/contact-center',
  }),
  CONTACT_CENTER: (t: TFunction) => ({
    id: 'contact-center',
    check: (user) => checkContactCenter(user),
    label: t('baseLayout.menuItems.contactCenterAds'),
    path: '/contact-center',
  }),
  CONTACT_CENTER_WIDGET: (t: TFunction) => ({
    id: 'contact-center-widget',
    check: (user) => checkContactCenterWidget(user),
    label: t('baseLayout.menuItems.contactCenterWidget'),
    path: '/contact-center-widget',
  }),
  DEMO_WIDGET: () => ({
    id: 'demo-widget',
    check: (user) => user?.account === 'AIR',
    label: 'Widget sito',
    url: 'https://valutazione-auto.myair.io/widget/AIR',
  }),
  THIRD_FLAVOR_VEHICLE_OVERVIEW: (t: TFunction) => ({
    id: 'vehicle-overview',
    check: (user) => checkThirdFlavorFeaturesEnabled(user),
    label: t('baseLayout.menuItems.thirdFlavorFeaturesVehicles'),
    path: '/third-flavor/vehicle-overview',
  }),
  THIRD_FLAVOR_EVENTS: (t: TFunction) => ({
    id: '',
    check: (user) =>
      checkThirdFlavorFeaturesEnabled(user) &&
      checkThirdFlavorFeaturesEnabled(user, [NEW_ROLES.CHANNELING_EVENTSREAD, NEW_ROLES.CHANNELING_EVENTSMANAGE]),
    label: t('baseLayout.menuItems.thirdFlavorFeaturesEvents'),
    path: '/third-flavor/events',
  }),
  USER_MANAGEMENT: (t: TFunction) => ({
    id: 'user-management',
    anyOfPages: [NEW_ROLES.USER_ADMINISTRATOR],
    label: t('baseLayout.menuItems.userManagement'),
    path: '/user-management',
  }),
  ACCOUNT_MANAGEMENT: (t: TFunction) => ({
    id: 'account-management',
    anyOfPages: [NEW_ROLES.ACCOUNT_ADMINISTRATOR],
    label: t('baseLayout.menuItems.accountManagement'),
    path: '/account-management',
  }),
  EVENTS_ADMINISTRATION: (t: TFunction) => ({
    id: 'events-administration',
    anyOfPages: [NEW_ROLES.EVENTS_ADMINISTRATOR],
    label: t('baseLayout.menuItems.eventsAdministration'),
    path: '/events-administration',
  }),
  DATA_HUB_DRIVER: (t: TFunction) => ({
    id: 'data-hub-driver',
    anyOfPages: [NEW_ROLES.DATAHUB_DRIVER],
    label: t('baseLayout.menuItems.dataHubDriver'),
    path: '/data-hub-driver/all',
  }),
};

const DATA_HUB: MenuItem = {
  id: 'data-hub',
  check: (user) => checkDataHubFeaturesEnabled(user),
  label: 'Data Hub',
  children: [
    {
      id: 'data-hub-b2b',
      label: 'B2B',
      path: '/data-hub/b2b',
    },
    {
      id: 'data-hub-b2b2c',
      label: 'B2B2C',
      path: '/data-hub/b2b2c',
    },
    {
      id: 'data-hub-ariel',
      label: 'Ariel',
      path: '/data-hub/ariel',
    },
    {
      id: 'data-hub-all',
      label: 'Tutto',
      path: '/data-hub/all',
    },
  ],
};

export const useMenu = (section: string) => {
  const { t } = useTranslation();
  const auth = useAuth() || {};
  const user = useMemo(() => auth.user || undefined, [auth.user]);

  const newLayout = useMemo(() => user?.newLayout, [user]);

  const legacyMenu: MenuItem[] = useMemo(() => {
    return [
      PAGES.MONITORING(t),
      {
        id: 'todo-folder',
        anyOfPages: [NEW_ROLES.CARS_TO_PRICE],
        label: t('baseLayout.menuItems.todo'),
        children: [
          PAGES.CARS_TO_PRICE(t),
          PAGES.CARS_TO_PRICE_DRIVER(t),
          PAGES.CARS_TO_PRICE_FROM_ADS(t),
          PAGES.CARS_TO_PRICE_B2C(t),
          PAGES.CARS_TO_PRICE_CHANNELING(t),
        ],
      },
      PAGES.OPEN_SEARCH(t),
      PAGES.EVENTS(t),
      {
        ...PAGES.EVENT_HISTORY(t),
        enabled: !isWithEvaluationHistory(user),
      },
      {
        ...PAGES.CARS_TO_PRICE_CHANNELING(t),
        noPages: [NEW_ROLES.CARS_TO_PRICE],
      },
      {
        ...PAGES.VEHICLES_CHANNELING_HISTORY(t),
        noPages: [NEW_ROLES.EVAL_HISTORY],
      },
      {
        id: 'evaluation-history-group',
        check: (user) => shouldDisplayAppraisalsFeature(user),
        label: t('baseLayout.menuItems.evaluationHistory'),
        children: [
          PAGES.EXTENDED_EVALUATION_HISTORY_PRICED_VEHICLE_LIST(t),
          PAGES.EVALUATION_HISTORY_DRIVER(t),
          PAGES.EXTENDED_EVALUATION_HISTORY_ESTIMATED_PRICE_VEHICLE_LIST(t),
          PAGES.EXTENDED_EVALUATION_HISTORY_PICKEDUP_VEHICLE_LIST(t),
          PAGES.EXTENDED_EVALUATION_HISTORY_PRICED_VEHICLE_LIST_SECOND_FLAVOR(t),
          { ...PAGES.VEHICLES_CHANNELING_HISTORY(t) },
          {
            ...PAGES.EVENT_HISTORY(t),
            enabled: isWithEvaluationHistory(user),
          },
        ],
      },
      PAGES.MARKETPLACE(t),
      PAGES.STOCK_ANALYSIS(t),
      PAGES.POSITIONING_ANALYSIS(t),

      {
        id: 'evaluations-appraisals',
        label: t('baseLayout.menuItems.appraisalsInformationGathering'),
        children: [
          PAGES.EVALUATIONS_APPRAISALS_NEW(t),
          PAGES.EVALUATIONS_APPRAISALS(t),
          PAGES.EVALUATIONS_APPRAISALS_CONFIRMED(t),
        ],
      },
      // PAGES.CONTACT_CENTER_ADS(t),
      {
        id: 'contact-center',
        check: (user) => checkContactCenter(user),
        label: t('baseLayout.menuItems.contactCenter'),
        children: [PAGES.CONTACT_CENTER(t), PAGES.CONTACT_CENTER_WIDGET(t)],
      },
      {
        id: 'third-flavor-features',
        label: t('baseLayout.menuItems.thirdFlavorFeatures'),
        children: [PAGES.THIRD_FLAVOR_VEHICLE_OVERVIEW(t), PAGES.THIRD_FLAVOR_EVENTS(t)],
      },
      DATA_HUB,
      PAGES.DATA_HUB_DRIVER(t),
      PAGES.USER_MANAGEMENT(t),
      PAGES.ACCOUNT_MANAGEMENT(t),
      PAGES.EVENTS_ADMINISTRATION(t),
    ] satisfies MenuItem[];
  }, [t, user]);

  const newMenu: MenuItem[] = useMemo(() => {
    return [
      {
        ...PAGES.OPEN_SEARCH(t),
        check: (user) => shouldDisplayAppraisalsFeature(user) && checkPickupPriceVisible(user),
        label: t('baseLayout.menuItems.searchNew'),
      },

      {
        id: 'evaluations-appraisals',
        label: t('baseLayout.menuItems.appraisalsInformationGatheringNew'),
        children: [
          PAGES.EVALUATIONS_APPRAISALS_NEW(t),
          PAGES.EVALUATIONS_APPRAISALS(t),
          PAGES.EVALUATIONS_APPRAISALS_CONFIRMED(t),
          PAGES.CARS_TO_PRICE_WEBAPP_VIDEO(t),
        ],
      },
      {
        id: 'todo-folder',
        anyOfPages: [NEW_ROLES.CARS_TO_PRICE],
        label: t('baseLayout.menuItems.todoNew'),
        children: [
          {
            ...PAGES.OPEN_SEARCH(t),
            label: 'Singola auto',
            path: '/search?no-pickup-price',
            id: 'search-no-pickup-price',
          },
          {
            ...PAGES.CARS_TO_PRICE(t),
            label: 'Da flusso',
          },
          {
            ...PAGES.CARS_TO_PRICE_DRIVER(t),
            label: 'Da flusso Driver',
          },
          { ...PAGES.CARS_TO_PRICE_FROM_ADS(t), label: 'Da annunci' },
          PAGES.CARS_TO_PRICE_B2C(t),
          PAGES.CARS_TO_PRICE_CHANNELING(t),
        ],
      },
      {
        id: 'evaluation-history-group',
        check: (user) => shouldDisplayAppraisalsFeature(user),
        label: t('baseLayout.menuItems.evaluationHistoryNew'),
        children: [
          PAGES.EXTENDED_EVALUATION_HISTORY_PRICED_VEHICLE_LIST(t),
          PAGES.EVALUATION_HISTORY_DRIVER(t),
          PAGES.EXTENDED_EVALUATION_HISTORY_ESTIMATED_PRICE_VEHICLE_LIST(t),
          PAGES.EXTENDED_EVALUATION_HISTORY_PICKEDUP_VEHICLE_LIST(t),
          PAGES.EXTENDED_EVALUATION_HISTORY_PRICED_VEHICLE_LIST_SECOND_FLAVOR(t),
          { ...PAGES.VEHICLES_CHANNELING_HISTORY(t) },
          {
            ...PAGES.EVENT_HISTORY(t),
            enabled: isWithEvaluationHistory(user),
          },
        ],
      },
      PAGES.EVALUATION_HISTORY_DRIVER(t),
      PAGES.MONITORING(t),
      PAGES.EVENTS(t),
      {
        ...PAGES.EVENT_HISTORY(t),
        enabled: !isWithEvaluationHistory(user),
      },
      {
        ...PAGES.CARS_TO_PRICE_CHANNELING(t),
        noPages: [NEW_ROLES.CARS_TO_PRICE],
      },
      {
        ...PAGES.VEHICLES_CHANNELING_HISTORY(t),
        noPages: [NEW_ROLES.EVAL_HISTORY],
      },
      PAGES.STOCK_ANALYSIS(t),
      PAGES.POSITIONING_ANALYSIS(t),
      {
        id: 'third-flavor-features',
        label: t('baseLayout.menuItems.thirdFlavorFeatures'),
        children: [PAGES.THIRD_FLAVOR_VEHICLE_OVERVIEW(t), PAGES.THIRD_FLAVOR_EVENTS(t)],
      },
      DATA_HUB,
      PAGES.DATA_HUB_DRIVER(t),
      {
        id: 'administration',
        label: t('baseLayout.menuItems.administration'),
        children: [PAGES.USER_MANAGEMENT(t), PAGES.ACCOUNT_MANAGEMENT(t), PAGES.EVENTS_ADMINISTRATION(t)],
      },
    ] satisfies MenuItem[];
  }, [t, user]);

  const filterMenuItems = (menuItems: MenuItem[], user?: User) => {
    const filterItem = (item: MenuItem) => {
      let enabled = 'enabled' in item ? item.enabled : true;
      if ('anyOfPages' in item && item.anyOfPages) {
        enabled = enabled && checkAnyOfUserPages(user, item.anyOfPages);
      }
      if ('anyOfRoles' in item && item.anyOfRoles) {
        enabled = enabled && checkAnyOfUserRoles(user, item.anyOfRoles);
      }
      if ('noPages' in item && item.noPages) {
        enabled = enabled && !checkAnyOfUserPages(user, item.noPages);
      }
      if ('noRoles' in item && item.noRoles) {
        enabled = enabled && !checkAnyOfUserRoles(user, item.noRoles);
      }
      if ('check' in item && item.check) {
        enabled = enabled && item.check(user);
      }
      return enabled;
    };

    return menuItems
      .filter(filterItem)
      .map((item) => {
        if ('children' in item) {
          return {
            ...item,
            children: item.children.filter(filterItem),
          };
        }
        return item;
      })
      .filter((item) => {
        if ('children' in item) {
          return item.children.length > 0;
        }
        return true;
      });
  };

  const menuItemsEnabled = useMemo(() => {
    return filterMenuItems(newLayout ? newMenu : legacyMenu, user);
  }, [legacyMenu, newLayout, newMenu, user]);

  const LinkItem = ({ item }: { item: MenuLink }) =>
    'path' in item ? (
      <Nav.Link
        className={section === item.id ? 'underlined' : 'normal'}
        eventKey={item.id}
        as={Link}
        to={item.path}
        data-testid={`${item.id}-nav-item`}
      >
        {item.label}
      </Nav.Link>
    ) : (
      <Nav.Link
        className="normal"
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={`${item.id}-nav-item`}
      >
        {item.label}
      </Nav.Link>
    );

  const buyFromPages = useMemo(
    () =>
      filterMenuItems(
        [
          { ...PAGES.MARKETPLACE(t), label: 'Dealer' },
          {
            label: 'Privati',
            id: 'contact-center',
            children: [PAGES.CONTACT_CENTER(t), PAGES.CONTACT_CENTER_WIDGET(t), PAGES.DEMO_WIDGET(t)],
          },
        ],
        user,
      ),
    [t, user],
  );

  const RenderItem = ({ item }: { item: MenuItem }) => (
    <Nav.Item>
      {'children' in item ? (
        item.children.length === 1 ? (
          <LinkItem item={{ ...item.children[0], label: item.label }} />
        ) : (
          <Dropdown className="nav-link">
            <Dropdown.Toggle as={DropdownToggle} active={item.children.some((child) => child.id === section)}>
              {item.label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {item.children.map((child) => (
                <Dropdown.Item key={child.id} as={LinkItem} item={child}>
                  {child.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      ) : (
        <LinkItem item={item} />
      )}
    </Nav.Item>
  );

  const Menu = () => (
    <>
      {newLayout && buyFromPages.length > 0 && (
        <>
          <Nav variant="tabs" className=" first-nav-tabs">
            <Nav.Item>
              <span className="navbar-buy-from">{t('baseLayout.menuItems.buyFrom')}:</span>
            </Nav.Item>
            {buyFromPages.map((item) => (
              <RenderItem key={item.id} item={item} />
            ))}
          </Nav>
          <div className="divider-vertical" />
        </>
      )}
      <Nav variant="tabs" activeKey={null} className="custom-nav-tabs">
        {menuItemsEnabled.map((item) => (
          <RenderItem key={item.id} item={item} />
        ))}
      </Nav>
    </>
  );

  return { menuItems: legacyMenu, Menu };
};
