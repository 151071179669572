import { createSlice } from '@reduxjs/toolkit';

export const vehiclesSecondFlavorSlice = createSlice({
  name: 'vehiclesSecondFlavor',
  initialState: {
    attributes: {},
  },
  reducers: {
    setAttributes: (state, { payload }) => {
      return { ...state, attributes: { ...state.attributes, ...payload } };
    },
  },
});

export const { setAttributes, setEvaluation, setEvaluationMeta, setEvaluationData } = vehiclesSecondFlavorSlice.actions;

export default vehiclesSecondFlavorSlice.reducer;
