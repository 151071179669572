import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import tableEn from '../../translations/en.json';
import tableIt from '../../translations/it.json';

const defaultLanguage = 'en';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: tableEn,
      },
      it: {
        translation: tableIt,
      },
    },
    fallbackLng: defaultLanguage,
    supportedLngs: ['en', 'it'],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
    },
  });

export default i18n;
