import React from 'react';

const SvgComponent = (props, ref) => (
  <svg
    data-testid="icon"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...{ ...{ width: '1em', height: '1em' }, ...props }}
  >
    <image
      width="512"
      height="512"
      preserveAspectRatio="none"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0eNT6AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAABZtSURB
VHic7d17sG53Xd/x9znJyQm5x0BJEQJEbmJAkJtgerFNGZ1KtdX0IpW2FB1GWp12UOqoUzpIxbG2
pbZ1gOpYbEebab0xIEprGRou0silFRKuIeUSAknIPSHJOad/LPaQ5CTknL2f9fz2ftbrNfP9cz/P
97f23uv3WZffWgUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCx+abqDdVV1d3VEbUr64bqD6oXVvvv
9zcJAMdgX/XTmfT3Yr2zOvfoXykAPLh/1viJTG2/Lq/OOuq3CqzUCaMbgBV7WvWfm84CsDc9tDqj
esvoRmCT2UmyaX6j+hujm2DH7mq6FHD96EZgU7nhhk2yr/rO0U2wEgeqi0Y3AZtMAGCTnN106pjN
8NjRDcAmEwDYJKeMboCV8vuEGQkAALBAAgAALJAAAAALJAAAwAIJAACwQCeObgB2gZuqj41uYoOd
XZ0/ugng3gQAqD+qnj+6iQ12cXXJ6CaAe3MJAAAWSAAAgAUSAABggQQAAFggAQAAFkgAAIAFEgAA
YIEEAABYIAEAABZIAACABRIAAGCBBAAAWCABAAAWSAAAgAUSAABggQQAAFggAQAAFkgAAIAFEgAA
YIEEAABYIAEAABZIAACABRIAAGCBBAAAWCABAAAWSAAAgAUSAABggQQAAFigE0c3ALvAY6pX7PAz
TqkeW51ZHdhpQ7vAXdVN1ZXVrTv8rKfsvB1g1QQAqMdXrxndBMA6uQQAAAskAADAAgkAALBAAgAA
LJAAAAALJACwSQ6NboCV8vuEGQkAbJIvZtLYJJ8f3QBsMgGATXJ39d7RTbAyl45uADaZAMCm+eXR
DbAS764+PLoJAPaOE6p3VkfUnq3bq2fe9xcLAA/m4dX7Gz+RqeOv26q/dvSvFACOzanVz1Q3Nn5S
Uw9eh6q35sVBsDb7RjcAMztYPad6dPWQFX3mN1Q/vo2f+3j1nhX1sArPqL5xGz/3i9WfrKiHw9U1
1WXV1Sv6TACYxbe1vaPc149o9mv4hbY3ju8c0SywWlYBAMACCQAAsEACAAAskAAAAAskAADAAgkA
ALBAAgAALJAAAAALJAAAwAIJAACwQAIAACyQAAAACyQAAMACCQAAsEACAAAskAAAAAskAADAAgkA
ALBAAgAALJAAAAALJAAAwAIJAACwQAIAACyQAAAACyQAAMACCQAAsEACAAAskAAAAAskAADAAgkA
ALBAAgAALJAAAAALtG90A3Af+6o/U31fdX51cGw79+us6pnb+LnD1aEV97ITJ7S9g4D3V9etuJed
OlJdU/1h9ZvVjWPbAeBY7a9eUF3WtDNXart1c/Xa6lEBsGvtry6uPtz4iUNtVt1ZvbG6IAB2jQPV
i6orGj9RqM2vS5vOMAEwyElNE/9HGz8pqOXV+5r+/k4IgLU4qfqh6tONnwSU+kT1o9UpATCLU5t2
tJ9t/E5fqfvWF6pXVucEwEqc1jTxX934nbxSD1ZbKwfOC4BtOb16RdM68dE7daWOtw5Vb6qeFQDH
5JymU6lfavxOXKlVlJUDAF/Dw5om/hsav8NWao56f9PKgRMDoIdXr6lubfwOWql11CezcgBYsPOa
bpa6rfE7ZKVG1BezcoAN4GVAHKvHVT9R/UDTU/zW6fbqjjV/5/HY3/TSogNN/1Ob8n91uLqr6ZG6
u+klRvd0cvWQQd99a/Ur1b+sPjWoB4DZnF+9rmkiWPeR1jVNKwqccuWBHGy6Pv+Rxp0R2Fo58OyZ
xwqwFhc0vUjl7ta/Q72q6VrrqCM79p6tt0m+p7GXB7ZWDmzKWSBgQZ7aNPEfav07z081Tfwnzz1I
NtqFTUfkhxsXBD6QlQPAHvH06pLG7DSvbJr4D84+SpZkK8yOuHx137/tU2ceK8Bx2zpaGrFz/ETT
C4IcJTGnRzetXLmlcUHghq/08KdnHivAgxo58X8or2Rl/c5s/Iup7mg6K/HEmccKcJSLqnc1Zuf3
fzPxM97WyoErGhcEtlYOfOvMYwUWbl/Tncl/1Jid3Qebdrj75x4oHIetlQPvblwQOJKVA8AMtnZw
lzVmx/b+6uLs2Nj9dsPKga2gvO4HbQEbZH/TxPvhxuzI3pkjGvampzRdo7+zcUHgc02PGj5z3qEC
m+RAY69ten0qm+Lcxr/e+samlQOPmHeowF52UtPE/7HGTfwXzT5KWL8zmlYOfKZxQeDLTWclnjTz
WIE95GDTOvpPN27i//bZRwnjbYXsUZfVjvTVlQPPnXmswC52amPXM7+tes7so4TdZ+vG2nc2Lghs
hW/32cCCnNY08V/d+nc4h5uOPp41+yhhb9gNKwc+mvdnwEY7vem1uNc1buJ/xuyjhL3p8U03693e
uCBwddNNi2fNO1RgXR7auDuRt643Pn3uQcKG2A0rB25qCiNfP+9Q2QSuH+1O51Yvr17a+t8idnf1
69Wrq4+s+bthE5zV9L/7I417+c/WyoG3zfDZtzedcfhodfMMnw+L9Mim9H5b6z9yuLP6lepxs48S
luFg9ZLq8sadEZj7LOH7mg4WnryibQaL85jql5reFLbuf+IvV6+rHjv3IGGh9lff0/iVA3PXW7M6
CI7Z45qOukc8dvSO6t9V580+SmDLhdXvNB09j56w56jD1RuaViwB9+NR1a81XW9f9z/obblJCEb7
xuqXm87AjZ6056grqiesbGvBhvhb1Q2t/x/yluoXmm4wBHaHR1Q/15h9wtz1heppq9tUsLd9R+s/
6r+5ek31sDWMD9ieM6ofa+w7B+aoz1fnr3A7sUKWAa7PKdVVTWv71+Gm6herf9X0ACFg9zupemHT
MuBNubP+fdXzmi53jHSwaT+8Vx1q2q+zB7209STu65seRnL2WkYFzGFf07P+39H4o/hV1KtXu3mO
2Tlf+e6PHkOPe6Furn6z+rOr3EjM75Lm/cO4tvqpplOJwOZ4btNOfy+vHLij9V8K+PPVF3fY926u
f18dWNXGYl6XNc8fwRea3hNw+vqGAgzwhOr1jXleyCrqdavfJA/oWY15oNomb1N24IpWP/G/Mkf8
sDR/qul/f8TLwXZSt1dnrn5zHOWE6k8GjG9UPX81m405rSoAfKbpGeMPWW/7wC5zevWPq//X+Eno
WOtvz7Il7u0vDxzfiPqD1Ww25rTTAHBV9cNNd7ICbDlQvah6b+Mnower/zjTNrinfzNwfCPqzrY5
L5y4nR9ira5sWsf/q02/aIB7uqvpzX9vbHq095Oa71T7eU1H8dt9wM863hWwtMebH2h6oNSVoxvh
gW3nDMD/yV2ewO5yoOk1w9s9Wp37wPOt2+xtL9e2nhmxfzs/xNrc1pTuAXaLu6p/us2fPVCdtcJe
2AEBAIDjdfkOfvbUlXXBjggAAByvQzv4WfPOLuEXAQALJAAAwAJZBggAdWnTY5ZHemrTkx7XQgAA
gPr+6tODe/iv1feu68tWFQBOqv5KdVH19dXJK/rcudxVXV29vfqt6pah3QDAmq0iAHx79R9a/6se
V+HF1eerf9iUvABgEXZ6E+D3VL/f3pz8t5xbXdL0nH0AWISdBIDzqv/UZjyqdl/12uqZoxsBgHXY
SQD4yTbriU4nVq8a3QQArMN2A8D+1nin4hpdVJ09ugkAmNt2A8DDq3NW2cgucWL1xNFNAMDcthsA
NvltTs4AALDxthsA9q20i91lk8cGAJV3AQDAIgkAALBAAgAALJAAAAALJAAAwAKt+3XAb61etqbv
+onqJWv6LgDYU9YdAG6tPrmm77phTd8DAHuOSwAAsEACAAAs0LovAQCwbL9bfXnGz3/8jJ+9UQQA
ANbpgtENMHEJAAAWSAAAgAUSAABggQQAAFggAQCA43XC6AZmcNroBtZNAADgeJ0yuoEZnDG6gXUT
AABggQQAAFggAQAAFkgAAIAF8ijgo31f9ZQZPvfrZvhMANgWAeBof290AwAwN5cAAFin86t9M9a1
6xvK3iYAAMACbXIA2De6gRU4OLoBADbTJgeAR45uYAXOGd0AAJtpkwPAuaMbWIHFPZsagPXY5ACw
CafPrdIAYBabHABuH93ACtw1ugEANtMmB4DPjG5gBW4a3QAAm2mTA8A1oxtYgetHNwDAZtrkALAJ
XAIAYBYCAAAskAAAAAtkmdnRfrZ6/wyf+6+rR8zwuQBw3ASAo11avWWGz33VDJ8JANviEgAALJAA
AAALJAAAwAIJAACwQAIAACyQAAAACyQAAMACCQAAsEACAAAskAAAAAskAADAAgkAALBAAgAALJAA
AAALJAAAwAIJAACwQAIAACyQAAAACyQAAMACCQAAsEACAAAskAAAAAskAADAAgkAALBAAgAALJAA
AAALdOLoBnah06qzZ/jcE2b4TADYFgHgaP9ldAMAMDeXAABggQQAAFggAWB38/sBYBabPMGcMbqB
FZjjZkQA2OgA8JjRDazAWaMbAGAzbXIAOH10Aytw0ugGANhMmxwADo1uYAUOj24AgM20yQHgutEN
rMDtoxsAYDNtcgD41OgGVuD60Q0AsJk2OQDcNbqBFbhldAMAbKZNDgAAwAPwLgAA1unt1d0zfr7n
pxwjAeBoH6iuneFzn1edMsPnAuwl541ugIkAcLSfrN4yw+deUT1xhs8FgOPmHgAAWCABAAAWSAAA
gAUSAABggQQAAI7XHaMbmMFtoxtYNwEAgOO1CU9ava8bRjewbgIAACyQAAAACyQAAMACCQAAsEAC
AAAskAAAwDqdX+2bsX5/fUPZ2wQAAFggAQAAFmjdrwM+qTp7Td918pq+BwD2nHUHgBdU16/5OwGA
+3AJAAAWSAAAgAUSAABggQQAAFggAQAAFkgAONqR0Q0AwNy2GwBuWGkXu8uXRjcAAHPbbgC4ps2c
KA9VHxvdBADMbbsB4FD126tsZJd4e3Xd6CYAYG47uQfgZ6rbV9XILnC4+unRTQDAOuwkAHyy+vtN
ZwM2wSuqd49uAgDWYaerAH69+u7qsyvoZZTrq79T/YvRjQDAuqziZUBvrp5Q/fXqouq86pQVfO6c
7qg+V/3P6jfazBsaAeABreptgLdVv/qVAgB2uXW/DhgAdqPXVLcM7uFb1vllAgAA1PePbmDdPAoY
ABZIAACABRIAAGCBBAAAjtf5O/jZTXqC7J4mAABwvP7BNn/uSHXjKhth+6wC2N2eUb2x+ufVFYN7
AfaGh1enzvTZj61e3PbvmL+6+c8A3Dnz5+9GXx7dAF/bFU3pdzt1qOmxyxesvWtgL3hq9frq2ra/
n1lHvXmuDXAPvzRwfCPq7nb/03cXbycB4J5B4L9VT1tz78Du9Beq32t6m+noiehY6uXzbIZ7uXjg
+EbU/1rNZmNOqwgA96y3Vc9Z6wiA3WB/9YKmt5eOnnyOpw63s5sHj9VJ1ScGjG9UXbyazcacPtQ8
v/zfq563xnEAY5xSvay9O7n94eo3yQN6ftOp8dFjnrt+u9q3om3GjN7SvH8I/6P6c2sbDbAuD61e
WX2x8RPOTuovrXi7PJi/2fSiutHjnqt+pzptZVuLWb269fxRvKP1/6MBq3d+9W+rWxs/2ey01nHz
3/15fPVr1U3H0ONeqMPVZdULW8EyfqcO1ufR1cdb39LL91SvajrzAOwdz6h+vPre6oTBvazCjdXT
qysH9nBS0z74jIE97NRd1Wer60Y3wvb8bOtPjJdV352wB7vZvuo7mi7ljT7KXGUdqv7qCrcT7Fn7
m9bzj/hH/GDT3aKe/gi7x4HqB5r+P0dP1nPUj6xuU8FmeHHjHtjxoaaneG3CqUXYq06v/lF1VeMn
6TnqruolK9tasGFOa7rOd01j/kE/Uv3dPA4a1uncpkd7X9/4SXquuqr6tlVtMNhkp1Q/2nRzx4h/
1k9WP9h0kwwwjyc2Par3jsZP0HPVrdVrsjQNjtvB6oerTzUutb+sOnnmccKSPK/6raab4UZP0HPV
5dVPVQ9b0TZjTdwZvvucVL2o+ifVNwz4/s9VP990tHLbgO+HvW5/9V3Vj1UXDuzjfzcdUKzaLU1v
9bu8emdjl/fBRtrfdNf+5Y1J9V9oevrYmTOPEzbFVnif67Hfx1KHqzdVF808VmANtl788ceN2aFc
2xQEzpp5nLBXnd50H8+nGzfxf7l6Y/XkmccKDLCvKQi8tzE7mJuabvD5urkHCnvEuU3h+EuNm/hv
ql5bPXLeoQK7xUWNexXozU07nHNnHyXsThdUr6tub9zEf3XOzMGiXVj998bsgG5pCgKPmH2UsDtc
2HR9/XDjJv6PNl1usFoHqL66YxqxQ7qj6WjIKUg20dY9OO9q3KR/pLr0K31YuQXcr+c27ghl6yak
EUsXYdUONt3RP2oVzpGmZwe8qen/GuCYfHN1SWOCwJ1NQeDxs48SVu/Mxj6Z80jTWbU3Vk+aeazA
BrugaUdyd2OOXi7JToy94TFN97Tc0riJ/4bcVwOs2JObgsBdjQkCb6qePvso4fh9c+P+N7bqyqaz
DqfOPFZgwR7bdIQx4oUkW0HgGbOPEh7cbrij/wNN9xl4IyewNo9uCgKj1jG/rXr27KOEe9u6o3/U
w7S2yh39wHAPb3q6322NCwLfOvsoWbrTqh9qWkM/atLfOgP2rJnHCnBcHtb0VLEbG3dE9BfnHiSL
s/V3fW3jJv6bm56TYVUMsKs9tLHPNt86NQo7cX7TJa5RZ7aO9NW3aZ4z71ABVuuM6hXVdY3Zeb4r
10g5ft/SuGWvW/WJpjv6T5l5rACzOq1pZ3Z1Y3amH6guThDgge1rejnWqEdhb9UfN93Rf8K8w4XV
sWPlWJxWvbR6edONg+v2yabLEnBf5zQ9xGeEI9Wbq5+v3jGoB4C1ONh0N/WnG3u0pdTI2nrvxTcF
sDAnNZ3u/Fjjd8ZKratuarq58FEBLNyBpiBwReN3zkrNVZ9vuqP/7AC4l/1NN+t9uPE7a6VWVR9r
ugn25AD4mrYetXpZ43feSm23Lm0KtPsD4Ljsa3c8c12pY62tR/U+LwBW4qLq3Y3fwSt1f3VH0x39
TwqAWVzY9PKf0Tt8pY40vffitdUjAmAttt7DPnoCUMusTzU95vrMABji6dUl1eHGTwpq8+uDTUtW
DwTArvDUpmuwhxo/SajNq603THrsOcAudUHj3+SmNqO27uh/dsADkorZbZ5U/WD1XdWjm94/AA/m
jurj1e9Wb2i61g8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMDa/H80Z0kbEDd+kwAAAABJRU5ErkJg
gg==
"
    />
  </svg>
);

const CarEngineIcon = React.forwardRef(SvgComponent);
export default CarEngineIcon;
