import React from 'react';
import tomasiLogo from '../assets/images/tomasi-logo.svg';
import automobileitLogo from '../assets/images/automobileit.png';
import autoscout24Logo from '../assets/images/autoscout24.png';
import autouncleLogo from '../assets/images/autouncle.png';
import subitoitLogo from '../assets/images/subitoit.png';
import CarDoorIcon from '../assets/icons/car-door-icon';
import CarEngineIcon from '../assets/icons/car-engine-icon';
import CarKeyIcon from '../assets/icons/car-key-icon';
import CarSeatIcon from '../assets/icons/car-seat-icon';

export const ARVAL_ACCOUNT = 'ARVAL';

export const ARVAL_OWNER = 'ArvalItaly';

export const TOMASI_ACCOUNT = 'TOMASI';

export const ARIEL_ACCOUNT = 'ARIEL';

export const GUIDI_ACCOUNT = 'GUIDICAR';

export const NUOVESOLUZIONI_ACCOUNT = 'NUOVESOLUZIONI';

export const RESICAR_ACCOUNT = '1284502';

export const LAYOUT_BREAKPOINTS = {
  fluid: 0,
  static: 1600,
};

export const vehicleIndex = (vehicle) => {
  return vehicle.CONS_INDEX ? vehicle.CONS_INDEX.toUpperCase() : null;
};

export const vehicleIsIndexB2C = (vehicle) => {
  const index = vehicleIndex(vehicle);
  return index ? index.endsWith('SI') || index.endsWith('CONDIZIONALE') : false;
};

export const PORTALS = ['TOMASI', 'AUTOUNCLE', 'AUTOSCOUT24', 'SUBITO', 'AUTOMOBILEIT'];

export const PICKUP_PRICE_CALCULATION_VALUES = {
  SOLD: 'SOLD',
  AVG_SOLD: 'AVG_SOLD',
  OFFERING: 'OFFERING',
  AVG_SOLD_OFFERING: 'AVG_SOLD_OFFERING',
  AVG_SOLD_OFFERING_EUROTAX: 'AVG_SOLD_OFFERING_EUROTAX',
  AVG_SOLD_OFFERING_INDICATA: 'AVG_SOLD_OFFERING_INDICATA',
  AVG_SOLD_OFFERING_INFOCAR: 'AVG_SOLD_OFFERING_INFOCAR',
};

export const PICKUP_PRICE_CALCULATION_EUROTAX_VALUES = {
  BLU: 'quotazioneEurotaxBlu',
  BLU_KM: 'quotazioneEurotaxBluKm',
  GIALLO: 'quotazioneEurotaxGiallo',
  GIALLO_KM: 'quotazioneEurotaxGialloKm',
};

export const PORTALS_META = [
  {
    key: 'TOMASI',
    mediaComponent: <img height={24} src={tomasiLogo} alt={'Logo'} />,
  },
  {
    key: 'AUTOUNCLE',
    mediaComponent: <img height={24} src={autouncleLogo} alt={'Logo'} />,
  },
  {
    key: 'AUTOSCOUT24',
    mediaComponent: <img height={24} src={autoscout24Logo} alt={'Logo'} />,
  },
  {
    key: 'SUBITO',
    mediaComponent: <img height={24} src={subitoitLogo} alt={'Logo'} />,
  },
  {
    key: 'AUTOMOBILEIT',
    mediaComponent: <img height={24} src={automobileitLogo} alt={'Logo'} />,
  },
];

export const portalMedia = (key) => {
  const commonStyle = { fontWeight: 'bold', fontSize: '14px' };
  const mediaComponent = (
    <div style={key === 'Tomasi' ? { ...commonStyle, color: 'red' } : commonStyle}>
      {key === 'Tomasi' || key === 'Guidicar' ? 'Dealers' : 'Portal'}
    </div>
  );
  return (PORTALS_META.find((p) => p.key === key) || { mediaComponent }).mediaComponent;
};

export const EVALUATIONS_TAX_SYSTEMS = [
  {
    key: 1,
    value: 'TAXSYSTEM_IT_REGIMEDELMARGINE',
  },
  {
    key: 2,
    value: 'TAXSYSTEM_IT_IVAESPOSTACENTOPERCENTO',
  },
  {
    key: 3,
    value: 'TAXSYSTEM_IT_IVAESPOSTAQUARANTAPERCENTO',
  },
];

export const APPRAISALS_TAX_SYSTEMS = [
  {
    key: 1,
    value: 'TAXSYSTEM_IT_REGIMEDELMARGINE',
  },
  {
    key: 2,
    value: 'TAXSYSTEM_IT_IVAESPOSTACENTOPERCENTO',
  },
  {
    key: 3,
    value: 'TAXSYSTEM_IT_IVAESPOSTAQUARANTAPERCENTO',
  },
];

export const APPRAISALS_VEHICLE_PROPERTY_DEFINITIONS = [
  {
    key: 1,
    value: 'PROPERTY_DEFINITION_IT_FULLPROPERTY',
  },
  {
    key: 2,
    value: 'PROPERTY_DEFINITION_IT_LEASING',
  },
  {
    key: 3,
    value: 'PROPERTY_DEFINITION_IT_FINANCING',
  },
];

export const APPRAISALS_ORIGIN_DEFINITIONS = [
  {
    key: 'CONTACTCENTER',
    value: 'CONTACTCENTER',
  },
  {
    key: 'EXPERT',
    value: 'EXPERT',
  },
  {
    key: 'WEBSITE',
    value: 'WEBSITE',
  },
  {
    key: 'MOBILE',
    value: 'MOBILE',
  },
  {
    key: 'WIDGET',
    value: 'WIDGET',
  },
];

export const APPRAISALS_LEGAL_ENTITY__DEFINITIONS = [
  {
    key: 1,
    value: 'LEGAL_ENTITY_IT_LEGALPERSON',
  },
  {
    key: 2,
    value: 'LEGAL_ENTITY_IT_NATURALPERSON',
  },
];

export const NEW_ROLES = {
  DATA_MONITOR: 'DataMonitor',
  CARS_TO_PRICE: 'CarsToPrice',
  CARS_TO_PRICE_DRIVER: 'CarsToPriceDriver',
  OPEN_SEARCH: 'OpenSearch',
  EVAL_HISTORY: 'EvaluationHistory',
  EVAL_HISTORY_DRIVER: 'EvaluationHistoryDriver',
  USER_ADMINISTRATOR: 'UserAdministrator',
  ACCOUNT_ADMINISTRATOR: 'AccountAdministrator',
  USER_MONITORING: 'UserMonitoring',
  STOCK_ANALYSIS: 'StockAnalysis',
  POSITIONING_ANALYSIS: 'PositioningAnalysis',
  CARS_TO_PRICE_FROM_ADS: 'CarsToPriceFromAds',
  APPRAISALS_MANAGEMENT: 'AppraisalsManagement',
  EVALUATIONS_REVIEWER: 'EvaluationsReviewer',
  EVALUATIONS_STOREMANAGER: 'EvaluationsStoreManager',
  EVALUATIONS_MORESTOREMANAGER: 'EvaluationsMoreStoreManager',
  EVENTS: 'Events',
  EVENTS_ADMINISTRATOR: 'EventsAdministrator',
  CONTACT_CENTER: 'ContactCenter',
  CHANNELING: 'Channeling',
  MARKETPLACE: 'MarketplaceUser',
  CHANNELING_EVENTSMANAGE: 'ThirdFlavorFeaturesEventsManage',
  CHANNELING_EVENTSREAD: 'ThirdFlavorFeaturesEventsRead',
  DATAHUBFEATURES_MANAGE: 'DatahubFeaturesManage',
  DATAHUBFEATURES_READ: 'DatahubFeaturesRead',
  DATAHUB: 'DataHub',
  DATAHUB_DRIVER: 'DataHubDriver',
  VIEW_USERS_LOG: 'ViewUsersLog',
  VIEW_USERS_EVENT_LOG: 'ViewUsersEventLog',
};
export const SPI_THRESHOLDS = [1400, 1500];

export const GROUP_ACCOUNTS = [
  'UNCATEGORIZED',
  'ENTERPRISE',
  'MEDI',
  'PICCOLI',
  'DEMO',
  'TRIAL',
  'EVENTI ARVAL',
  'MARKETPLACE',
];

export const valueGradeFromSPIValue = ({ value, valueSPI }) => {
  const valueRa = value / valueSPI;
  if (valueSPI <= 12000) {
    const thresholds = [1100, 1199];
    if (value < thresholds[0]) {
      return 'red';
    }
    if (value >= thresholds[0] && value <= thresholds[1]) {
      return 'yellow';
    }
    return 'green';
  }
  if (valueSPI > 12001 && valueSPI <= 15000) {
    const thresholdsRa = [0.095, 0.098];
    if (valueRa < thresholdsRa[0]) {
      return 'red';
    }
    if (valueRa >= thresholdsRa[0] && valueRa <= thresholdsRa[1]) {
      return 'yellow';
    }
    return 'green';
  }
  if (valueSPI > 15001 && valueSPI <= 20000) {
    const thresholdsRa = [0.085, 0.095];
    if (valueRa < thresholdsRa[0]) {
      return 'red';
    }
    if (valueRa >= thresholdsRa[0] && valueRa <= thresholdsRa[1]) {
      return 'yellow';
    }
    return 'green';
  }
  if (valueSPI > 20001 && valueSPI <= 30000) {
    const thresholdsRa = [0.08, 0.09];
    if (valueRa < thresholdsRa[0]) {
      return 'red';
    }
    if (valueRa >= thresholdsRa[0] && valueRa <= thresholdsRa[1]) {
      return 'yellow';
    }
    return 'green';
  }
  if (valueSPI > 30001 && valueSPI <= 40000) {
    const thresholdsRa = [0.075, 0.085];
    if (valueRa < thresholdsRa[0]) {
      return 'red';
    }
    if (valueRa >= thresholdsRa[0] && valueRa <= thresholdsRa[1]) {
      return 'yellow';
    }
    return 'green';
  }
  const thresholdsRa = [0.07, 0.08];
  if (valueRa < thresholdsRa[0]) {
    return 'red';
  }
  if (valueRa >= thresholdsRa[0] && valueRa <= thresholdsRa[1]) {
    return 'yellow';
  }
  return 'green';
};

export const valueGradeFromB2BSPIValue = ({ value, valueSPI }) => {
  if (value < deltaMin(valueSPI)) {
    return 'red';
  }
};

export const deltaMin = (valueSPI) => {
  let deltaMin = 0;
  if (valueSPI <= 15000) {
    deltaMin = 700;
  } else if (valueSPI <= 20000) {
    deltaMin = 800;
  } else if (valueSPI <= 22000) {
    deltaMin = 900;
  } else if (valueSPI <= 25000) {
    deltaMin = 1000;
  } else if (valueSPI <= 28000) {
    deltaMin = 1100;
  } else if (valueSPI <= 30000) {
    deltaMin = 1200;
  } else if (valueSPI <= 35000) {
    deltaMin = 1300;
  } else if (valueSPI <= 40000) {
    deltaMin = 1500;
  } else {
    deltaMin = 2000;
  }
  return deltaMin;
};

export const mediaComponentFromFamily = (family) => {
  if (family.description === 'CARROZZERIA') {
    // d441e38c-49de-462b-940b-aae2e6abec26
    return <CarDoorIcon style={{ fontSize: 20 }} />;
  }
  if (family.description === 'MOTORE') {
    // 61cf041b-819b-42f0-b3cb-00e112706c60
    return <CarEngineIcon style={{ fontSize: 20 }} />;
  }
  if (family.description === 'INTERNI') {
    // fa78b75e-d086-4ad8-bd0e-bf7e137731cb
    return <CarSeatIcon style={{ fontSize: 20 }} />;
  }
  if (family.description === 'ALTRI DANNI') {
    // 8338f313-fb00-4f81-b35c-ca025067fdc3
    return <CarKeyIcon style={{ fontSize: 20 }} />;
  }
  return <></>;
};

export const ACTIONS = [
  {
    key: 'ACTION1',
    value: 'https://meetings.hubspot.com/a-antonaci',
  },
];
