import { createSlice } from '@reduxjs/toolkit';

const initialMarketing1Visible = true;

export const applicationUserSessionSlice = createSlice({
  name: 'applicationUserSession',
  initialState: {
    marketing1Visible: initialMarketing1Visible,
  },
  reducers: {
    setMarketing1Visible: (state, { payload: value }) => {
      state.marketing1Visible = value;
    },
  },
});

export const { setMarketing1Visible } = applicationUserSessionSlice.actions;

export default applicationUserSessionSlice.reducer;
