import React from 'react';

const SvgComponent = (props, ref) => (
  <svg
    ref={ref}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={props.fill ? props.fill : '#223239'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8315 8.77085C13.0493 7.88954 13.8462 6.46062 13.8462 4.84615C13.8462 2.17454 11.6723 0 9 0C6.32769 0 4.15385 2.17454 4.15385 4.84615C4.15385 6.46062 4.95069 7.88954 6.16846 8.77085C2.59062 9.96092 0 13.3352 0 17.3077C0 17.6905 0.310154 18 0.692308 18H17.3077C17.6898 18 18 17.6905 18 17.3077C18 13.3352 15.4094 9.96092 11.8315 8.77085ZM5.53846 4.84615C5.53846 2.93746 7.09131 1.38462 9 1.38462C10.9087 1.38462 12.4615 2.93746 12.4615 4.84615C12.4615 6.75485 10.9087 8.30769 9 8.30769C7.09131 8.30769 5.53846 6.75485 5.53846 4.84615ZM1.41577 16.6154C1.76677 12.7392 5.03377 9.69231 9 9.69231C12.9662 9.69231 16.2332 12.7392 16.5842 16.6154H1.41577Z"
      fill={props.fill ? props.fill : '#223239'}
    />
  </svg>
);

const UserIcon = React.forwardRef(SvgComponent);
export default UserIcon;
