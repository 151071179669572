export const checkAppraisalsSystemEnabled = (user = {}) => {
  const { appraisalsSystemEnabled = false } = user || {};

  if (appraisalsSystemEnabled) {
    return true;
  }
  return false;
};

export const checkAppraisalsOperations = (user = {}) => {
  const { roles } = user;
  const allowedRoles = ['AppraisalsOperator'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkAppraisalsRead = (user = {}) => {
  const { roles } = user;
  const allowedRoles = ['AppraisalsExpert', 'AppraisalsReviewer'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkAppraisalsConfirm = (user = {}) => {
  const { roles } = user;
  const allowedRoles = ['AppraisalsExpert', 'AppraisalsReviewer'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkAppraisalsConfigurator = (user = {}) => {
  const { roles } = user;
  const allowedRoles = ['AppraisalsReviewer'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkAppraisalsBundledWithOtherFeatures = (user = {}) => {
  const { roles = [] } = user || {};
  const allowedRoles = ['AppraisalsReviewer'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkContactCenter = (user = {}) => {
  const { roles = [] } = user;
  const allowedRoles = ['ContactCenter'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkContactCenterWidget = (user = {}) => {
  const { roles = [] } = user;
  const allowedRoles = ['ContactCenterWidget'];
  return allowedRoles.some((role) => roles.includes(role));
};

export const checkPickupPriceVisible = (user = {}) => {
  return user.lightVersion && user.someReadOnlyData === false;
};

export const checkThirdFlavorFeaturesEnabled = (user = {}, allowedRoles = []) => {
  const { thirdFlavorFeaturesEnabled = false } = user || {};
  const { roles } = user;

  if (thirdFlavorFeaturesEnabled) {
    if (allowedRoles.length === 0) {
      return true;
    }
    return allowedRoles.some((role) => roles.includes(role));
  }
  return false;
};

export const checkDataHubFeaturesEnabled = (user = {}, allowedRoles = []) => {
  const { dataHubEnabled = false } = user || {};
  const { roles } = user;

  if (dataHubEnabled) {
    if (allowedRoles.length === 0) {
      return true;
    }
    return allowedRoles.some((role) => roles.includes(role));
  }
  return false;
};

export const checkDatahubFeaturesEnabled = (user = {}, allowedRoles = []) => {
  const { datahubFeaturesEnabled = false } = user || {};
  const { roles } = user;

  if (datahubFeaturesEnabled) {
    if (allowedRoles.length === 0) {
      return true;
    }
    return allowedRoles.some((role) => roles.includes(role));
  }
  return false;
};
