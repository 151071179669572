import { createSlice } from '@reduxjs/toolkit';

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    value: [],
    stockAnalysisClusterDimensionColumns: [],
  },
  reducers: {
    setFiltersDomain: (state, action) => {
      return { ...state, value: action.payload };
    },
    setStockAnalysisClusterDimensionColumns: (state, action) => {
      return { ...state, stockAnalysisClusterDimensionColumns: action.payload };
    },
  },
});

export const { setFiltersDomain, setStockAnalysisClusterDimensionColumns } = analysisSlice.actions;

export default analysisSlice.reducer;
