import { createSlice } from '@reduxjs/toolkit';

export const filterPanelLayoutSlice = createSlice({
  name: 'filterPanelLayout',
  initialState: {
    showFilters: false,
    filterApplied: false,
    OdoMeter: null,
    plateNumber: '',
    finalRes: {},
    dropdownValues: [],
    selectedDropdownValuesArray: {},
    stopApiCalling: true,
    setTelaio: '',
    eurotaxEvaluation: {
      prezzoListino: null,
      prezzoPrimaImmatricolazione: null,
      quotazioneEurotaxBlu: null,
      quotazioneEurotaxBluKm: null,
      quotazioneEurotaxGiallo: null,
      quotazioneEurotaxGialloKm: null,
    },
  },
  reducers: {
    setShowFilters: (state, action) => {
      return { ...state, showFilters: action.payload };
    },
    setFilterApplied: (state, action) => {
      return { ...state, filterApplied: action.payload };
    },
    setOdoMeter: (state, action) => {
      return { ...state, OdoMeter: action.payload };
    },
    setPlateNumber: (state, action) => {
      return { ...state, plateNumber: action.payload };
    },
    setFinalRes: (state, action) => {
      return { ...state, finalRes: action.payload };
    },
    selectedDropdown: (state, action) => {
      return { ...state, dropdownValues: action.payload };
    },
    selectedDropdownValues: (state, action) => {
      return { ...state, selectedDropdownValuesArray: { ...state.selectedDropdownValuesArray, ...action.payload } };
    },
    stopApiCall: (state, action) => {
      return { ...state, stopApiCalling: action.payload };
    },
    setTelaio: (state, action) => {
      return { ...state, telaioField: action.payload };
    },
    setEurotaxEvaluation: (state, action) => {
      return { ...state, eurotaxEvaluation: { ...action.payload } };
    },
  },
});

export const {
  setShowFilters,
  setFilterApplied,
  setOdoMeter,
  setPlateNumber,
  setFinalRes,
  selectedDropdown,
  selectedDropdownValues,
  stopApiCall,
  setTelaio,
  setEurotaxEvaluation,
} = filterPanelLayoutSlice.actions;

export default filterPanelLayoutSlice.reducer;
