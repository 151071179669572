import { configureStore } from '@reduxjs/toolkit';
import filterPanelReducer from '../slices/filterPanelSlice';
import analysisReducer from '../slices/analysisSlice';
import filterPanelLayoutReducer from '../slices/filterPanelLayoutSlice';
import vehicleListSliceReducer from '../slices/vehicleListSlice';
import evaluationsReducer from '../slices/evaluationsSlice';
import accountSettingsReducer from '../slices/accountSettingsSlice';
import vehiclesFavouritesReducer from '../slices/vehiclesFavouritesSlice';
import vehiclesSecondFlavorReducer from '../slices/vehiclesSecondFlavorSlice';
import appraisalsReducer from '../slices/appraisalsSlice';
import applicationUserSessionReducer from '../slices/applicationUserSessionSlice';

const store = configureStore({
  reducer: {
    filterPanel: filterPanelReducer,
    analysis: analysisReducer,
    filterPanelLayout: filterPanelLayoutReducer,
    vehicleList: vehicleListSliceReducer,
    evaluations: evaluationsReducer,
    accountSettings: accountSettingsReducer,
    vehiclesFavourites: vehiclesFavouritesReducer,
    vehiclesSecondFlavor: vehiclesSecondFlavorReducer,
    appraisals: appraisalsReducer,
    applicationUserSession: applicationUserSessionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
