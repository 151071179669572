import React from 'react';
import BaseLayout from '../layouts';
import { Spinner } from 'react-bootstrap';
import { List } from 'react-content-loader';

export default function AppLoading({ section }) {
  return (
    <BaseLayout section={section}>
      <div
        data-testid={'app-loading'}
        style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', top: '50%' }}
      >
        <Spinner variant={'primary'} size={'sm'} animation={'grow'} />
      </div>
    </BaseLayout>
  );
}

export const LoadingChart = () => <List height={'90%'} style={{ marginTop: 10 }} />;
