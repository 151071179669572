import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setMarketing1Visible } from '../../slices/applicationUserSessionSlice';
import { ACTIONS } from '../../utils/constants';

import './user-action-panel.scss';

const UserActionPanel = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const marketing1Visible = useSelector((state) => state.applicationUserSession.marketing1Visible);

  const handleClick = () => {
    const action = ACTIONS.find((a) => a.key === 'ACTION1').value;
    window.open(action, '_blank', 'noreferrer');
  };

  const handleClose = (e) => {
    e.stopPropagation();
    dispatch(setMarketing1Visible(false));
    window.dispatchEvent(new Event('resize'));
  };

  const string2 = t('userActions.action1.string2');

  return marketing1Visible ? (
    <div data-testid="user-action-panel" className={classnames(className, 'user-action-panel')} onClick={handleClick}>
      {t('userActions.action1.string1', { string2: '' })}
      <span className={'action-card-alt1'}>{string2}</span>
      <span className={'action1-dismiss'} onClick={handleClose}>
        ✕
      </span>
    </div>
  ) : (
    <></>
  );
};

export default UserActionPanel;
