import { createSlice } from '@reduxjs/toolkit';

const initialFavourites = [];

export const vehiclesFavouritesSlice = createSlice({
  name: 'favourites',
  initialState: {
    favourites: initialFavourites,
  },
  reducers: {
    setFavourites: (state, { payload: value }) => {
      state.favourites = value;
    },
  },
});

export const { setFavourites } = vehiclesFavouritesSlice.actions;

export default vehiclesFavouritesSlice.reducer;
