import React, { useEffect, useState } from 'react';
import Routes from '../../routes/routes';
import './app.scss';
import { ProvideAuth, RedirectToLoginOn401, useAuth } from '../../auth';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faUser, faKey, faCheck } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter } from 'react-router-dom';
import numeral from 'numeral';
import AppLoading from '../app-loading';
import './i18n';

export function init() {
  library.add(faSearch, faUser, faKey, faCheck);
}

init();

// load a locale
numeral.register('locale', 'it', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
numeral.locale('it');

function App() {
  return (
    <BrowserRouter>
      <ProvideAuth>
        <AuthLoading />
        <RedirectToLoginOn401 />
      </ProvideAuth>
    </BrowserRouter>
  );
}

export function AuthLoading() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    (async () => {
      try {
        await auth.refresh();
      } catch (e) {
        // history.push("/login")
        // console.log('e', e)
      }
      if (mounted) setLoading(false);
    })();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  if (loading) return <AppLoading />;
  return <Routes />;
}

export default App;
