import { faCogs, faEye, faSignOutAlt, faUserCog, faUserLock, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Container, Dropdown, Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import UserIcon from '../../assets/icons/user-icon';
import logo from '../../assets/images/air-logo-green.png';
import arielLogo from '../../assets/images/ariel-logo.svg';
import arvalLogo from '../../assets/images/arval-logo.svg';
import guidiLogo from '../../assets/images/guidi-logo.png';
import nuovesoluzioniLogo from '../../assets/images/nuovesoluzioni-logo.png';
import resicarLogo from '../../assets/images/resicar-logo.png';
import tomasiLogo from '../../assets/images/tomasi-logo.svg';
import { useAuth } from '../../auth';
import UserActionPanel from '../../components/user-action-panel';
import {
  NEW_ROLES,
  checkArvalAdminEventsAdministrator,
  checkUserHasAccountGroups,
  checkAnyOfUserPages,
  checkVehiclestopriceChannelingEnabled,
  checkVehiclestopriceSecondFlavorEnabled,
} from '../../routes/routes';
import {
  ARIEL_ACCOUNT,
  ARVAL_ACCOUNT,
  GUIDI_ACCOUNT,
  TOMASI_ACCOUNT,
  NUOVESOLUZIONI_ACCOUNT,
  RESICAR_ACCOUNT,
} from '../../utils/constants';
import {
  checkAppraisalsBundledWithOtherFeatures,
  checkAppraisalsConfigurator,
  checkAppraisalsOperations,
  checkAppraisalsRead,
  checkAppraisalsSystemEnabled,
  checkContactCenter,
  checkDataHubFeaturesEnabled,
  checkThirdFlavorFeaturesEnabled,
  // checkDatahubFeaturesEnabled
} from '../../utils/roles';

import './base-layout.scss';
import { useMenu } from './use-menu';

const addBodyClass = (className) => document.body.classList.add(className);
const removeBodyClass = (className) => document.body.classList.remove(className);

const useBodyClass = (className, componentClassName) => {
  useEffect(() => {
    if (Array.isArray(componentClassName) && componentClassName.includes(className)) {
      Array.isArray(className) ? className.map(addBodyClass) : addBodyClass(className);
      return () => {
        Array.isArray(className) ? className.map(removeBodyClass) : removeBodyClass(className);
      };
    }
  }, [className, componentClassName]);
};

export default function BaseLayout({ children, section, className = [], testId = '', isContainerFluid = true }) {
  const { t } = useTranslation();
  const auth = useAuth() || {};
  const history = useHistory();
  const pickupPriceManagementEnabled = auth.user?.pickupPriceManagementEnabled;
  const accountManagementEnabled = auth.user?.accountManagementEnabled;
  const notContactCenter = auth.user && !checkAppraisalsOperations(auth.user);
  const datahubManagementEnabled = auth.user?.datahubFeaturesEnabled;

  const isUserRoleEvaluationsStoreManager = auth.user?.roles?.includes(NEW_ROLES.EVALUATIONS_STOREMANAGER);
  const isUserRoleEvaluationsMoreStoreManager = auth.user?.roles?.includes(NEW_ROLES.EVALUATIONS_MORESTOREMANAGER);
  const isWithPriceVehicleList = isUserRoleEvaluationsStoreManager === false || false;
  const isWithEstimatedPriceVehicleList = auth.user?.pickupPriceListingEvaluation || false;
  const isWithPickedupVehicleList = auth.user?.pickupPriceListingPickedup || false;
  const isWithEvents = checkAnyOfUserPages(auth.user, [NEW_ROLES.EVENTS]);
  const isWithVehiclestopriceChanneling = checkVehiclestopriceChannelingEnabled(auth.user, [NEW_ROLES.CHANNELING]);
  const isWithCarsToPriceDriver = checkAnyOfUserPages(auth.user, [NEW_ROLES.CARS_TO_PRICE_DRIVER]);
  const isWithCarsToPriceFromAds = checkAnyOfUserPages(auth.user, [NEW_ROLES.CARS_TO_PRICE_FROM_ADS]);
  const isWithVehiclestopriceSecondFlavor = checkVehiclestopriceSecondFlavorEnabled(auth.user);
  const isWithExtendedCarsToPrice =
    isWithCarsToPriceDriver ||
    isWithCarsToPriceFromAds ||
    isWithVehiclestopriceSecondFlavor ||
    isWithVehiclestopriceChanneling;
  const isWithEvaluationHistory = checkAnyOfUserPages(auth.user, [NEW_ROLES.EVAL_HISTORY]);
  const isWithEvaluationHistoryDriver = checkAnyOfUserPages(auth.user, [NEW_ROLES.EVAL_HISTORY_DRIVER]);
  const isWithExtendedEvaluationHistory =
    isWithEvaluationHistoryDriver ||
    isWithEstimatedPriceVehicleList ||
    isWithPickedupVehicleList ||
    isWithEvents ||
    isWithVehiclestopriceChanneling;
  const isWithCarsToPrice = checkAnyOfUserPages(auth.user, [NEW_ROLES.CARS_TO_PRICE]);
  const isUserRoleDatahubManage = auth.user?.roles?.includes(NEW_ROLES.DATAHUBFEATURES_MANAGE);

  const visiblePickupPriceManagement = pickupPriceManagementEnabled && !isUserRoleEvaluationsMoreStoreManager;
  const visibleAccountManagement = accountManagementEnabled && !isUserRoleEvaluationsMoreStoreManager;
  const visibleDatahubManagement = datahubManagementEnabled && isUserRoleDatahubManage;

  const EvaluationHistoryToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={
        section === 'evaluation-history' ||
        section === 'vehicles-channeling-history' ||
        section === 'driver-evaluation-history'
          ? { color: '#2BB5AE', fontWeight: 600 }
          : { color: '#223239' }
      }
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  EvaluationHistoryToggle.displayName = 'EvaluationHistoryToggle';

  const CarsToPriceToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={
        section === 'todo' || section === 'vehicles-channeling'
          ? { color: '#2BB5AE', fontWeight: 600 }
          : { color: '#223239' }
      }
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  CarsToPriceToggle.displayName = 'CarsToPriceToggle';

  const EvaluationAppraisalsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={section === 'evaluations-appraisals' ? { color: '#2BB5AE', fontWeight: 600 } : { color: '#223239' }}
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  EvaluationAppraisalsToggle.displayName = 'EvaluationAppraisalsToggle';

  const ContactCenterToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={section === 'contact-center' ? { color: '#2BB5AE', fontWeight: 600 } : { color: '#223239' }}
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));

  ContactCenterToggle.displayName = 'ContactCenterToggle';

  const ThirdFlavorFeaturesToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={section === 'third-flavor-features' ? { color: '#2BB5AE', fontWeight: 600 } : { color: '#223239' }}
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  ThirdFlavorFeaturesToggle.displayName = 'ThirdFlavorFeaturesToggle';

  const DataHubToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      style={section === 'data-hub-features' ? { color: '#2BB5AE', fontWeight: 600 } : { color: '#223239' }}
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  DataHubToggle.displayName = 'DataHubToggle';

  const { isInSpecialGroup = false } = auth?.user || {};

  useBodyClass('using-palette', className);
  useBodyClass('using-v202202-palette', className);

  const isClientFromMobile = isMobile;
  const unmask = async () => {
    if (auth.user.impersonate && auth.user.previousUsername) {
      await auth.unmask();
      history.push('/');
    }
  };

  const { Menu } = useMenu(section);

  const _OldMenu = () =>
    auth.user &&
    !isMobile && (
      <Nav
        variant="tabs"
        // defaultActiveKey={section}
        activeKey={null}
        className="mr-auto custom-nav-tabs"
      >
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.DATA_MONITOR]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'monitoring' ? 'underlined' : 'normal'}
              eventKey="monitoring"
              as={Link}
              to={'/monitoring'}
            >
              {t('baseLayout.menuItems.monitoring')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.CARS_TO_PRICE]) && (
          <Nav.Item>
            {isWithExtendedCarsToPrice ? (
              <Dropdown className="nav-link">
                <Dropdown.Toggle as={CarsToPriceToggle} id="dropdown-todo">
                  {t('baseLayout.menuItems.todo')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/todo')}>
                    <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.menuItems.todo')}</span>
                  </Dropdown.Item>
                  {isWithCarsToPriceDriver && (
                    <Dropdown.Item className={'text-primary'} onClick={() => history.push('/tododriver')}>
                      <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.menuItems.todoDriver')}</span>
                    </Dropdown.Item>
                  )}
                  {isWithCarsToPriceFromAds && (
                    <Dropdown.Item className={'text-primary'} onClick={() => history.push('/todo-ads')}>
                      <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.menuItems.todoFromAds')}</span>
                    </Dropdown.Item>
                  )}
                  {isWithVehiclestopriceSecondFlavor && (
                    <Dropdown.Item className={'text-primary'} onClick={() => history.push('/vehicles-second-flavor')}>
                      <span className="ml-3 dropdown-user-menu-color">
                        {t('baseLayout.menuItems.vehiclestopriceSecondFlavor')}
                      </span>
                    </Dropdown.Item>
                  )}
                  {isWithVehiclestopriceChanneling && (
                    <Dropdown.Item className={'text-primary'} onClick={() => history.push('/vehicles-channeling')}>
                      <span className="ml-3 dropdown-user-menu-color">
                        {t('baseLayout.menuItems.vehiclestopriceChanneling')}
                      </span>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Nav.Link className={section === 'todo' ? 'underlined' : 'normal'} eventKey="todo" as={Link} to={'/todo'}>
                {t('baseLayout.menuItems.todo')}
              </Nav.Link>
            )}
          </Nav.Item>
        )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.OPEN_SEARCH]) &&
          ((checkAppraisalsSystemEnabled(auth.user) && checkAppraisalsBundledWithOtherFeatures(auth.user)) ||
            !checkAppraisalsSystemEnabled(auth.user)) && (
            <Nav.Item>
              <Nav.Link
                className={section === 'search' ? 'underlined' : 'normal'}
                eventKey="search"
                as={Link}
                to={'/search'}
              >
                {t('baseLayout.menuItems.search')}
              </Nav.Link>
            </Nav.Item>
          )}
        {isWithEvents && (
          <Nav.Item>
            <Nav.Link
              className={section === 'events' || section === 'todo-events' ? 'underlined' : 'normal'}
              eventKey="events"
              as={Link}
              to={'/events'}
            >
              {t('baseLayout.menuItems.events')}
            </Nav.Link>
          </Nav.Item>
        )}
        {isWithEvents && !isWithEvaluationHistory && (
          <Nav.Item>
            <Nav.Link
              className={section === 'events-history' ? 'underlined' : 'normal'}
              eventKey="events-history"
              as={Link}
              to={'/evaluation-history/events-vehicle-list'}
            >
              {t('baseLayout.menuItems.evaluationHistoryEventsVehicleList')}
            </Nav.Link>
          </Nav.Item>
        )}
        {isWithVehiclestopriceChanneling && !isWithCarsToPrice && (
          <Nav.Item>
            <Nav.Link
              className={section === 'vehicles-channeling' ? 'underlined' : 'normal'}
              eventKey="vehicles-channeling"
              as={Link}
              to={'/vehicles-channeling'}
            >
              {t('baseLayout.menuItems.vehiclestopriceChanneling')}
            </Nav.Link>
          </Nav.Item>
        )}
        {isWithVehiclestopriceChanneling && !isWithEvaluationHistory && (
          <Nav.Item>
            <Nav.Link
              className={section === 'vehicles-channeling-history' ? 'underlined' : 'normal'}
              eventKey="vehicles-channeling-history"
              as={Link}
              to={'/evaluation-history/priced-vehicle-list-channeling'}
            >
              {t('baseLayout.menuItems.evaluationHistoryPricedVehicleListChanneling')}
            </Nav.Link>
          </Nav.Item>
        )}
        {((checkAppraisalsSystemEnabled(auth.user) && checkAppraisalsBundledWithOtherFeatures(auth.user)) ||
          !checkAppraisalsSystemEnabled(auth.user)) && (
          <>
            {checkAnyOfUserPages(auth.user, [NEW_ROLES.EVAL_HISTORY]) && (
              <Nav.Item>
                {isWithExtendedEvaluationHistory ? (
                  <Dropdown className="nav-link">
                    <Dropdown.Toggle as={EvaluationHistoryToggle} id="dropdown-evaluation-history">
                      {t('baseLayout.menuItems.evaluationHistory')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {isWithPriceVehicleList && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/priced-vehicle-list')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryPricedVehicleList')}
                          </span>
                        </Dropdown.Item>
                      )}
                      {isWithEvaluationHistoryDriver && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/driver-priced-vehicle-list')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryDriver')}
                          </span>
                        </Dropdown.Item>
                      )}
                      {isWithEstimatedPriceVehicleList && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/estimated-price-vehicle-list')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryEstimatedPriceVehicleList')}
                          </span>
                        </Dropdown.Item>
                      )}
                      {isWithPickedupVehicleList && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/pickedup-vehicle-list')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryPickedupVehicleList')}
                          </span>
                        </Dropdown.Item>
                      )}
                      {isWithVehiclestopriceSecondFlavor && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/priced-vehicle-list-second-flavor')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryPricedVehicleListSecondFlavor')}
                          </span>
                        </Dropdown.Item>
                      )}
                      {isWithVehiclestopriceChanneling && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/priced-vehicle-list-channeling')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryPricedVehicleListChanneling')}
                          </span>
                        </Dropdown.Item>
                      )}
                      {isWithEvents && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/evaluation-history/events-vehicle-list')}
                        >
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.menuItems.evaluationHistoryEventsVehicleList')}
                          </span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Nav.Link
                    className={section === 'evaluation-history' ? 'underlined' : 'normal'}
                    eventKey="evaluation-history"
                    as={Link}
                    to={'/evaluation-history'}
                  >
                    {t('baseLayout.menuItems.evaluationHistory')}
                  </Nav.Link>
                )}
              </Nav.Item>
            )}
          </>
        )}
        {/* MARKETPLACE */}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.MARKETPLACE]) && (
          <Nav.Item>
            <Nav.Link
              data-testid="marketplace-nav-item"
              className={section === 'marketplace' ? 'underlined' : 'normal'}
              eventKey="marketplace"
              as={Link}
              to={'/marketplace'}
            >
              {t('baseLayout.menuItems.marketplace')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.STOCK_ANALYSIS]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'stock-analysis' ? 'underlined' : 'normal'}
              eventKey="stock-analysis"
              as={Link}
              to={'/stock-analysis-overall'}
            >
              {t('baseLayout.menuItems.stockAnalysis')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.POSITIONING_ANALYSIS]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'positioning-analysis' ? 'underlined' : 'normal'}
              eventKey="positioning-analysis"
              as={Link}
              to={'/positioning-analysis-overall'}
            >
              {t('baseLayout.menuItems.positioningAnalysis')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkAppraisalsSystemEnabled(auth.user) &&
          checkAppraisalsRead(auth.user) &&
          !checkAppraisalsOperations(auth.user) && (
            <Nav.Item>
              <Dropdown className="nav-link">
                <Dropdown.Toggle as={EvaluationAppraisalsToggle} id="evaluations-appraisals">
                  {t('baseLayout.menuItems.appraisalsInformationGathering')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className={'text-primary'}
                    onClick={() => history.push('/evaluations/appraisals?new=true')}
                  >
                    <span className="ml-3 dropdown-user-menu-color">
                      {t('baseLayout.menuItems.appraisalsNewEntity')}
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/evaluations/appraisals')}>
                    <span className="ml-3 dropdown-user-menu-color">
                      {t('baseLayout.menuItems.appraisalsListEntities')}
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={'text-primary'}
                    onClick={() => history.push('/evaluations/appraisals/confirmed')}
                  >
                    <span className="ml-3 dropdown-user-menu-color">
                      {t('baseLayout.menuItems.appraisalsConfirmedEntities')}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.CONTACT_CENTER]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'contact-center' ? 'underlined' : 'normal'}
              eventKey="contact-center"
              as={Link}
              to={'/contact-center'}
            >
              {t('baseLayout.menuItems.contactCenter')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkContactCenter(auth.user) && (
          <Nav.Item>
            <Dropdown className="nav-link">
              <Dropdown.Toggle as={ContactCenterToggle} id="contact-center">
                {t('baseLayout.menuItems.contactCenter')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className={'text-primary'} onClick={() => history.push('/contact-center')}>
                  <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.menuItems.contactCenterAds')}</span>
                </Dropdown.Item>
                <Dropdown.Item className={'text-primary'} onClick={() => history.push('/contact-center-widget')}>
                  <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.menuItems.contactCenterWidget')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        )}
        {checkThirdFlavorFeaturesEnabled(auth.user) && (
          <Nav.Item>
            <Dropdown className="nav-link">
              <Dropdown.Toggle as={ThirdFlavorFeaturesToggle} id="third-flavor-features">
                {t('baseLayout.menuItems.thirdFlavorFeatures')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {true && (
                  <Dropdown.Item
                    className={'text-primary'}
                    onClick={() => history.push('/third-flavor/vehicle-overview')}
                  >
                    <span className="ml-3 dropdown-user-menu-color">
                      {t('baseLayout.menuItems.thirdFlavorFeaturesVehicles')}
                    </span>
                  </Dropdown.Item>
                )}
                {checkThirdFlavorFeaturesEnabled(auth.user, [
                  'ThirdFlavorFeaturesEventsRead',
                  'ThirdFlavorFeaturesEventsManage',
                ]) && (
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/third-flavor/events')}>
                    <span className="ml-3 dropdown-user-menu-color">
                      {t('baseLayout.menuItems.thirdFlavorFeaturesEvents')}
                    </span>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        )}
        {checkDataHubFeaturesEnabled(auth.user) && (
          <Nav.Item>
            <Dropdown className="nav-link">
              <Dropdown.Toggle as={DataHubToggle} id="data-hub">
                Data Hub
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {true && (
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/data-hub/b2b')}>
                    <span className="ml-3 dropdown-user-menu-color">B2B</span>
                  </Dropdown.Item>
                )}
                {true && (
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/data-hub/b2b2c')}>
                    <span className="ml-3 dropdown-user-menu-color">B2B2C</span>
                  </Dropdown.Item>
                )}
                {true && (
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/data-hub/ariel')}>
                    <span className="ml-3 dropdown-user-menu-color">Ariel</span>
                  </Dropdown.Item>
                )}
                {true && (
                  <Dropdown.Item className={'text-primary'} onClick={() => history.push('/data-hub/all')}>
                    <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.menuItems.dataHubAll')}</span>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.USER_ADMINISTRATOR]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'user-management' ? 'underlined' : 'normal'}
              eventKey="user-management"
              as={Link}
              to={'/user-management'}
            >
              {t('baseLayout.menuItems.userManagement')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkAnyOfUserPages(auth.user, [NEW_ROLES.ACCOUNT_ADMINISTRATOR]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'account-management' ? 'underlined' : 'normal'}
              eventKey="account-management"
              as={Link}
              to={'/account-management'}
            >
              {t('baseLayout.menuItems.accountManagement')}
            </Nav.Link>
          </Nav.Item>
        )}
        {checkArvalAdminEventsAdministrator(auth.user, [NEW_ROLES.EVENTS_ADMINISTRATOR]) && (
          <Nav.Item>
            <Nav.Link
              className={section === 'events-administration' ? 'underlined' : 'normal'}
              eventKey="events-administration"
              as={Link}
              to={'/events-administration'}
            >
              {t('baseLayout.menuItems.eventsAdministration')}
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    );

  return (
    <>
      <Navbar sticky={'top mb-3'} className="header">
        <div style={{ flexGrow: 1 }}>
          {isInSpecialGroup && <UserActionPanel>hello world</UserActionPanel>}
          <Container fluid className={'p-0'}>
            <NavbarBrand
              onClick={() => {
                isClientFromMobile && history.push('/');
              }}
            >
              <img width={50} src={logo} alt={'Logo'} />
            </NavbarBrand>

            <Nav>
              <Nav.Item>
                <span className="navbar-header-title">
                  {auth.user && auth.user.account === ARVAL_ACCOUNT
                    ? t('common.banner.appealingPricingTool')
                    : t('common.banner.pricingTool')}
                </span>{' '}
                {/* TODO show banner based on user company */}
              </Nav.Item>
            </Nav>
            {!isMobile && <Menu />}

            {auth.user && auth.user.account === ARVAL_ACCOUNT && !isMobile && (
              <NavbarBrand style={{ borderRadius: 0 }}>
                <img width={100} src={arvalLogo} alt={'Logo'} />
              </NavbarBrand>
            )}
            {auth.user && auth.user.account === TOMASI_ACCOUNT && !isMobile && (
              <NavbarBrand style={{ borderRadius: 0 }}>
                <img width={100} src={tomasiLogo} alt={'Logo'} />
              </NavbarBrand>
            )}
            {auth.user && auth.user.account === ARIEL_ACCOUNT && !isMobile && (
              <NavbarBrand style={{ borderRadius: 0 }}>
                <img width={100} src={arielLogo} alt={'Logo'} />
              </NavbarBrand>
            )}
            {auth.user && auth.user.account === GUIDI_ACCOUNT && !isMobile && (
              <NavbarBrand style={{ borderRadius: 0 }}>
                <img width={100} src={guidiLogo} alt={'Logo'} />
              </NavbarBrand>
            )}
            {auth.user && auth.user.account === NUOVESOLUZIONI_ACCOUNT && !isMobile && (
              <NavbarBrand style={{ borderRadius: 0 }}>
                <img width={78} src={nuovesoluzioniLogo} alt={'Logo'} />
              </NavbarBrand>
            )}
            {auth.user && auth.user.account === RESICAR_ACCOUNT && !isMobile && (
              <NavbarBrand style={{ borderRadius: 0 }}>
                <img width={68} src={resicarLogo} alt={'Logo'} />
              </NavbarBrand>
            )}

            <Nav>
              <Nav.Item className="login-item" data-testid="user-menu">
                {!isMobile && auth.user && (
                  <>
                    <div className="login-username">
                      {auth.user.impersonate && auth.user.previousUsername && (
                        <>
                          <span>
                            {auth.user.previousUsername}
                            <span
                              onClick={() => unmask()}
                              style={{
                                fontSize: 'small',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                              }}
                            >
                              unmask
                            </span>
                          </span>
                          <br />
                          Masked as:
                        </>
                      )}{' '}
                      {auth.user.usr}
                    </div>
                  </>
                )}
                <Dropdown alignRight>
                  <Dropdown.Toggle id="dropdown-header">
                    <UserIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {isMobile && (
                      <>
                        <Dropdown.Header>{auth.user?.usr}</Dropdown.Header>
                        <Dropdown.Divider />
                      </>
                    )}
                    <Dropdown.Item className={'text-primary'} onClick={() => history.push('/change-password')}>
                      <FontAwesomeIcon icon={faUserLock} fixedWidth className={'dropdown-user-menu-color'} />
                      <span className="ml-3 dropdown-user-menu-color">
                        {t('baseLayout.profileItems.changePassword')}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item className={'text-primary'} onClick={() => history.push('/user-settings')}>
                      <FontAwesomeIcon icon={faUserCog} fixedWidth className={'dropdown-user-menu-color'} />
                      <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.profileItems.userSettings')}</span>
                    </Dropdown.Item>
                    {/** Disabled because now it's useless */}
                    {visibleAccountManagement && (
                      <Dropdown.Item className={'text-primary'} onClick={() => history.push('/account-settings')}>
                        <FontAwesomeIcon icon={faCogs} fixedWidth className={'dropdown-user-menu-color'} />
                        <span className="ml-3 dropdown-user-menu-color">
                          {t('baseLayout.profileItems.accountSettings')}
                        </span>
                      </Dropdown.Item>
                    )}
                    {visiblePickupPriceManagement && (
                      <Dropdown.Item className={'text-primary'} onClick={() => history.push('/pickup-price-settings')}>
                        <FontAwesomeIcon icon={faUserTag} fixedWidth className={'dropdown-user-menu-color'} />
                        <span className="ml-3 dropdown-user-menu-color">
                          {t('baseLayout.profileItems.pickupPriceSettings')}
                        </span>
                      </Dropdown.Item>
                    )}
                    {checkAppraisalsSystemEnabled(auth.user) &&
                      checkAppraisalsConfigurator(auth.user) &&
                      notContactCenter && (
                        <Dropdown.Item
                          className={'text-primary'}
                          onClick={() => history.push('/appraisals-system-configuration')}
                        >
                          <FontAwesomeIcon icon={faUserTag} fixedWidth className={'dropdown-user-menu-color'} />
                          <span className="ml-3 dropdown-user-menu-color">
                            {t('baseLayout.profileItems.appraisalsSystemConfigurator')}
                          </span>
                        </Dropdown.Item>
                      )}
                    {auth.user?.logSearchesEnabled && checkAnyOfUserPages(auth.user, [NEW_ROLES.USER_MONITORING]) && (
                      <Dropdown.Item className={'text-primary'} onClick={() => history.push('/user-monitoring')}>
                        <FontAwesomeIcon icon={faEye} fixedWidth className={'dropdown-user-menu-color'} />
                        <span className="ml-3 dropdown-user-menu-color">
                          {t('baseLayout.profileItems.userMonitoring')}
                        </span>
                      </Dropdown.Item>
                    )}
                    {checkAnyOfUserPages(auth.user, [
                      NEW_ROLES.EVENTS_ADMINISTRATOR,
                      NEW_ROLES.VIEW_USERS_EVENT_LOG,
                    ]) && (
                      <Dropdown.Item className={'text-primary'} onClick={() => history.push('/event-user-logs')}>
                        <FontAwesomeIcon icon={faEye} fixedWidth className={'dropdown-user-menu-color'} />
                        <span className="ml-3 dropdown-user-menu-color">
                          {t('baseLayout.profileItems.eventUserLogs')}
                        </span>
                      </Dropdown.Item>
                    )}
                    {checkAnyOfUserPages(auth.user, [NEW_ROLES.ACCOUNT_ADMINISTRATOR, NEW_ROLES.VIEW_USERS_LOG]) &&
                      checkUserHasAccountGroups(auth.user) && (
                        <Dropdown.Item className={'text-primary'} onClick={() => history.push('/user-logs')}>
                          <FontAwesomeIcon icon={faEye} fixedWidth className={'dropdown-user-menu-color'} />
                          <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.profileItems.userLogs')}</span>
                        </Dropdown.Item>
                      )}
                    {visibleDatahubManagement && (
                      <Dropdown.Item className={'text-primary'} onClick={() => history.push('/datahub-settings')}>
                        <FontAwesomeIcon icon={faCogs} fixedWidth className={'dropdown-user-menu-color'} />
                        <span className="ml-3 dropdown-user-menu-color">
                          {t('baseLayout.profileItems.datahubSettings')}
                        </span>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item href={'/logout'} className={'text-primary'}>
                      <FontAwesomeIcon icon={faSignOutAlt} fixedWidth className={'dropdown-user-menu-color'} />
                      <span className="ml-3 dropdown-user-menu-color">{t('baseLayout.profileItems.logout')}</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
            </Nav>
          </Container>
        </div>
      </Navbar>
      <Container fluid={isContainerFluid} className={className} data-testid={testId}>
        {children}
      </Container>
    </>
  );
}
