import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, Provider } from 'react-redux';
import { useAccountSettings } from '../routes/account-settings/account-settings-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { setDatahub, setFormulaOne } from '../slices/accountSettingsSlice';
import AuthService from './auth-service';
import { api } from '../utils/api';
import { useHistory } from 'react-router-dom';
import store from '../stores/appStore';
import { useDatahub } from '../hooks/datahub-hook';

const authContext = createContext(undefined);

export function RedirectToLoginOn401() {
  const history = useHistory();

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          history.push('/login');
        }

        return Promise.reject(error);
      },
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [history]);

  return null;
}

export function ProvideAuth({ children, defaultValue = null }) {
  const auth = useProvideAuth(defaultValue);
  return (
    <Provider store={store}>
      <authContext.Provider value={auth}>{children}</authContext.Provider>
    </Provider>
  );
}

/**
 * @returns {Auth}
 */
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * @returns {Auth}
 */
export function useProvideAuth(defaultValue) {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(defaultValue);
  const dispatch = useDispatch();
  const { getSettings, remoteSettings } = useAccountSettings();
  const { getSettings: getDatahubSettings, remoteSettings: datahubSettings } = useDatahub();
  const getSunriseSettingsCallback = async (user) => {
    try {
      await getSettings();
      if (user.datahubFeaturesEnabled) {
        await getDatahubSettings();
      }
    } catch (_) {}
  };
  useDeepCompareEffect(() => {
    if (remoteSettings) {
      dispatch(setFormulaOne(remoteSettings.formulaOne));
    }
  }, [remoteSettings || {}]);
  useDeepCompareEffect(() => {
    const value = datahubSettings === undefined || datahubSettings === null ? {} : datahubSettings;
    dispatch(setDatahub({ value }));
  }, [datahubSettings || {}]);

  const mask = async (username, password) => {
    const { expiredPassword, isFirstLogin, mfaEnabled, mfaSet, ...user } = await AuthService.mask(username, password);
    if (['it', 'it-IT'].includes(user.preferredLanguage)) {
      await i18n.changeLanguage('it');
    } else {
      await i18n.changeLanguage('en');
    }
    setUser(user);
    await getSunriseSettingsCallback(user);
    return { expiredPassword, isFirstLogin, mfaEnabled, mfaSet };
  };

  const unmask = async () => {
    const { expiredPassword, isFirstLogin, mfaEnabled, mfaSet, ...user } = await AuthService.unmask();
    if (['it', 'it-IT'].includes(user.preferredLanguage)) {
      await i18n.changeLanguage('it');
    } else {
      await i18n.changeLanguage('en');
    }
    setUser(user);
    await getSunriseSettingsCallback(user);
    return { expiredPassword, isFirstLogin, mfaEnabled, mfaSet };
  };

  const signin = async (username, password) => {
    const { expiredPassword, isFirstLogin, mfaEnabled, mfaSet, ...user } = await AuthService.signin(username, password);
    if (['it', 'it-IT'].includes(user.preferredLanguage)) {
      await i18n.changeLanguage('it');
    } else {
      await i18n.changeLanguage('en');
    }
    setUser(user);
    await getSunriseSettingsCallback(user);
    return { expiredPassword, isFirstLogin, mfaEnabled, mfaSet };
  };

  const refresh = async () => {
    const u = await AuthService.refresh();
    if (['it', 'it-IT'].includes(u.preferredLanguage)) {
      await i18n.changeLanguage('it');
    } else {
      await i18n.changeLanguage('en');
    }
    setUser(u);
    await getSunriseSettingsCallback(u);
  };

  const logout = async () => {
    await AuthService.logout();
    setUser(null);
  };

  useEffect(() => {
    // api.interceptors.request.use(config => {
    //     console.log("user",user)
    //     if (user)
    //         config.headers.authorization = `${user.token.token}`
    //     // else
    //     //     delete config.headers.authorization
    //     console.log('config.headers.authorization', config.headers.authorization)
    //     return config
    // }, error => Promise.reject(error))
  }, [user]);

  return {
    user,
    signin,
    refresh,
    logout,
    mask,
    unmask,
  };
}
