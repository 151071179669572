import React from 'react';
import airLogo from '../../assets/images/air-logo.png';
import './login-layout.scss';

export default function LoginLayout({ children }) {
  return (
    <div className="main justify-content-center">
      <div className="main-content">
        <img className={'mx-auto mb-3'} width={75} src={airLogo} alt={'Air'} />
        {children}
      </div>
    </div>
  );
}
