import axios from 'axios';
import { useEffect, useState } from 'react';
import { useMountedState } from '../hooks/use-mounted-hook';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

const defaultOptions = {
  params: {},
  defaultState: [],
  conditionCallback: () => true,
};

export function usePostApi(url, options = defaultOptions) {
  return useApi(url, { ...options, method: 'post' });
}

export function useGetApi(url, options = defaultOptions) {
  return useApi(url, { ...options, method: 'get' });
}

export function useApi(url, { method = 'post', params = {}, defaultState = [], conditionCallback = () => true }) {
  const [data, setData] = useState(defaultState);
  const isMounted = useMountedState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!conditionCallback()) {
        // console.log('ignoring api call because of conditionCallback', {url, method, params})
        setData(defaultState);
        return;
      }

      // console.log('calling api', {url, method, params})

      setLoading(true);
      try {
        let response = null;

        if (method === 'get') {
          response = await api.get(url, {
            params,
          });
        } else if (method === 'post') {
          response = await api.post(url, params);
        } else {
          console.warn('Invalid method passed to useApi', method);
        }

        if (response !== null && isMounted()) {
          setData(response.data);
        }
      } catch (e) {
        console.error('error on get api', e);
        throw e;
      } finally {
        if (isMounted()) {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    url,
    params /*
        method, => should be constant
        conditionCallback => avoid the "must" have a useCallback in the usage
    */,
  ]);

  return [data, isLoading];
}
