import { type PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../utils/api';
import type { RootState } from '../stores/appStore';

export interface VehicleListState {
  total: number;
  totalToBePricedOnly: number;
  totalDistinct: number;
  filters: Array<any>;
  account: object | null;
  rows: Array<Record<string, any>>;
  sorting: Array<any>;
  loading: boolean;
  currentPage: number;
  pageSize: number;
}

export const DEFAULT_LIST_VEHICLE_VALUE = {
  total: 0,
  totalToBePricedOnly: 0,
  totalDistinct: 0,
  filters: [],
  account: null,
  rows: [],
  sorting: [],
  loading: false,
  currentPage: 0,
  pageSize: 200,
} as VehicleListState;

export const vehicleListSlice = createSlice({
  name: 'vehicleList',
  initialState: { ...DEFAULT_LIST_VEHICLE_VALUE },
  reducers: {
    setList: (state, { payload }: PayloadAction<VehicleListState>) => {
      Object.assign(state, payload);
    },
    startLoading: (state) => {
      state.loading = true;
    },
    update: (state, { payload }) => {
      const vehicle = state.rows.find((vehicle) => vehicle.ID === payload.ID);
      if (vehicle) {
        vehicle.FLOW = payload.FLOW;
        vehicle.NOARIEL = payload.NOARIEL;
        vehicle.TEMP = payload.TEMP;
        vehicle.PRICE = payload.PRICE;
        vehicle.PRICE_RETAIL = payload.PRICE_RETAIL;
      }
    },
  },
});

export const { setList, startLoading, update } = vehicleListSlice.actions;

export default vehicleListSlice.reducer;

export const queryVehicleList = async ({
  currentPage,
  pageSize,
  sorting,
  filters,
  url,
  filterType,
  transformFilters,
}: Pick<VehicleListState, 'filters' | 'sorting' | 'currentPage' | 'pageSize'> & {
  url: string;
  filterType: string;
  transformFilters?: (filters: Array<any>, filterType?: string) => Array<any>;
}) => {
  const sortingConfig = sorting.map(({ columnName, direction }) => ({
    selector: columnName,
    desc: direction === 'desc',
  }));

  const skip = currentPage * pageSize;

  const params = {
    skip: Number.isNaN(skip) ? 0 : skip,
    take: pageSize,
    sorting: sortingConfig,
    filters: transformFilters ? transformFilters(filters, filterType) : filters,
  };

  try {
    return await api.post(url, params);
  } catch (e) {
    console.log(e);
  }
};

export const refreshListData = createAsyncThunk<
  void,
  {
    url: string;
    filterType: string;
    transformFilters?: (filters: Array<any>, filterType?: string) => Array<any>;
  } & Pick<VehicleListState, 'filters' | 'sorting' | 'currentPage' | 'pageSize'>,
  {
    state: RootState;
  }
>(
  'vehicleList/refreshListData',

  async (
    { url, filterType, transformFilters, filters = null, sorting = null, currentPage = null, pageSize = null },
    { dispatch, getState },
  ) => {
    const state = getState().vehicleList;
    const {
      filters: filtersState,
      sorting: sortingState,
      currentPage: currentPageState,
      pageSize: pageSizeState,
    } = state;

    // if no params are passed, use the current state
    filters = filters ?? filtersState;
    sorting = sorting ?? sortingState;
    currentPage = currentPage ?? currentPageState ?? 0;
    pageSize = pageSize ?? pageSizeState ?? 200;

    dispatch(startLoading());

    const response = await queryVehicleList({
      currentPage,
      pageSize,
      sorting,
      filters,
      url,
      filterType,
      transformFilters,
    });

    const { total = 0, totalToBePricedOnly = 0, totalDistinct = 0, result: rows = [] } = response?.data ?? {};

    if (response) {
      dispatch(
        setList({
          total,
          totalToBePricedOnly,
          totalDistinct,
          filters,
          account: null,
          loading: false,
          rows,
          sorting,
          currentPage,
          pageSize,
        }),
      );
    }
  },
);
